import React, { useState } from "react";
import { Button, Tooltip } from "antd";
import {
  IssuesCloseOutlined,
  LoadingOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { openNotificationWithIcon } from "../../../utils/common";

const CloseBop = (props: any) => {
  const { onSubmit } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Tooltip title="Zamknij zgłoszenie">
      <Button
        icon={
          isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <IssuesCloseOutlined style={{ color: "red" }} />
          )
        }
        onClick={() => {
          setIsLoading(true);
          onSubmit(
            {
              close: true,
            },
            () => {
              openNotificationWithIcon("success", "Zgłoszenie zamknięte.");
              setIsLoading(false);
            },
            () => setIsLoading(false)
          );
        }}
      />
    </Tooltip>
  );
};

export default CloseBop;
