import React, { PropsWithChildren, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Card, Col, Grid, Row, Tabs } from "antd";
import { AxiosError, AxiosResponse } from "axios";
import { FormikErrors } from "formik";
import { AppState } from "../../../reducers";
import {
  createUser,
  softDeleteUser,
  getTags,
  getUsersAdmin,
} from "../../../actions/users";

import List from "./list";
import {
  openNotificationWithIcon,
  transformToFormikError,
} from "../../../utils/common";
import Can from "../../Shared/Can";
import { MetaTitle } from "../../Shared/MetaTitle";
import useIsMobile from "../../../hooks/useIsMobile";
import AdminService from "../../../services/admin-service";

const { TabPane } = Tabs;

interface IUsersPageProps {
  users: any[];
  displayNotification: boolean;
  tags: any[];
  getUsers: () => void;
  getTagsAction: () => void;
  createUserAction: (user: any) => Promise<any>;
  deleteUserAction: (id: string) => Promise<any>;
  isRequest: boolean;
  isCreateRequest: boolean;
  isDeleteRequest: boolean;
}

const CompaniesPage = (
  props: PropsWithChildren<IUsersPageProps>
): JSX.Element => {
  const [visible, setVisible] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const [companies, setCompanies] = React.useState([]);

  const isMobile = useIsMobile();

  const {
    users,
    tags,
    displayNotification,
    isRequest,
    isCreateRequest,
    isDeleteRequest,
    createUserAction,
    deleteUserAction,
    getTagsAction,
  } = props;
  const handleCancel = () => {
    setVisible(false);
  };

  const fetchCompanies = () => {
    setIsFetching(true);
    AdminService.getCompanies()
      .then((res: any) => {
        console.log(res.data);
        setCompanies(res.data);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const decline = (id: string) => {
    AdminService.declineRegistration(id)
      .then((res: any) => {
        openNotificationWithIcon("success", "Rejestracja odrzucona");
      })
      .catch(() => {
        openNotificationWithIcon("error", "Wystąpił błąd");
      })
      .finally(() => {
        fetchCompanies();
      });
  };

  const accept = (id: string) => {
    AdminService.acceptRegistration(id)
      .then((res: any) => {
        openNotificationWithIcon("success", "Rejestracja zaakceptowana");
      })
      .catch(() => {
        openNotificationWithIcon("error", "Wystąpił błąd");
      })
      .finally(() => {
        fetchCompanies();
      });
  };

  const onCreate = (
    values: any,
    callbackResetForm: () => void,
    setFormErrors: (errors: FormikErrors<any>) => void
  ) => {
    createUserAction({
      ...values,
      username: values.email,
      tag: values.tag ? values.tag.map((tag: any) => tag.value) : null,
    })
      .then((response: AxiosResponse) => {
        setVisible(false);
        callbackResetForm();
        openNotificationWithIcon("success", "Użytkownik dodany");
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 400) {
          const formikResponse = transformToFormikError(err);
          setFormErrors(formikResponse);
        }
      });
  };

  const onDelete = (user: any, callbackHidePopover: () => void) => {
    deleteUserAction(user.id)
      .then(() => {
        openNotificationWithIcon("success", "Użytkownik usunięty");
        callbackHidePopover();
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 409) {
          openNotificationWithIcon(
            "error",
            `Nie można usunąć użytkownika ${user.email} ponieważ przynależy do wątków.`
          );
        }
        callbackHidePopover();
      });
  };

  useEffect(() => {
    fetchCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="users-page">
      <MetaTitle title="Rejestracje" displayBadge={displayNotification} />
      <Row gutter={16}>
        <Col span={24}>
          <div className="users-page__header-actions">
            <Button type="primary" onClick={() => setVisible(true)} disabled>
              Nowa firma
            </Button>
          </div>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Card
            bordered={false}
            style={{ width: "100%" }}
            size={isMobile ? "small" : "default"}
          >
            <List
              companies={companies}
              isRequest={isFetching}
              isDeleteRequest={isDeleteRequest}
              onDelete={onDelete}
              onAccept={accept}
              onDecline={decline}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapDispatchToProps = {
  getUsers: getUsersAdmin,
  getTagsAction: getTags,
  createUserAction: createUser,
  deleteUserAction: softDeleteUser,
};

const mapStateToProps = (state: AppState) => {
  return {
    users: state.users.users,
    tags: state.users.tags,
    isRequest: state.users.isRequest,
    isCreateRequest: state.users.isCreateRequest,
    isDeleteRequest: state.users.isRemoveRequest,
    displayNotification: state.notifications.newNotificationIndicator,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesPage);
