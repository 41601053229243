import { Badge, Button, Grid, Layout, Menu } from "antd";
import {
  CalendarOutlined,
  ContactsOutlined,
  DashboardOutlined,
  FolderOpenOutlined,
  HistoryOutlined,
  HomeOutlined,
  QuestionCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Can from "../Can";
import UserApp from "../../UserApp/UserApp";
import CompanyPicker from "../CompanyPicker/CompanyPicker";

const { Header, Sider, Content, Footer } = Layout;

const SideMenu = (props: any) => {
  const {
    collapsed,
    archivedCount,
    location,
    toggle,
    companies,
    currentCompany,
  } = props;
  const { useBreakpoint } = Grid;
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const screens = useBreakpoint();

  const catMenu = useRef<HTMLDivElement>(null);

  const toggleMenu = useCallback(() => {
    if (isMobile) toggle();
  }, [isMobile, toggle]);
  useEffect(() => {
    // eslint-disable-next-line eqeqeq

    const isMdBreakpoint = Object.entries(screens)
      .filter((screen) => !!screen[1])
      .filter((screen) => screen[0] === "md").length;

    setIsMobile(!isMdBreakpoint);

    if (isMdBreakpoint) {
      toggle(true);
    }
  }, [screens, setIsMobile, toggle]);

  return (
    <Sider
      ref={catMenu}
      trigger={null}
      collapsible
      collapsed={collapsed}
      className="dashboard__main-sider main-sider"
      theme="light"
      width={250}
      style={{
        overflow: "auto",
        height: "calc(100vh - 64px)",
        position: "fixed",
        left: isMobile && collapsed ? -80 : 0,
        top: 64,
      }}
    >
      <Menu
        mode="vertical"
        theme="light"
        defaultSelectedKeys={["/"]}
        selectedKeys={[location.pathname]}
      >
        <Menu.Item
          key="/"
          icon={<HomeOutlined style={{ fontSize: 18 }} />}
          className="menu__item"
          onClick={toggleMenu}
        >
          <Link to="/" className="nav-text">
            Lista firm
          </Link>
        </Menu.Item>

        <Menu.Item
          icon={<QuestionCircleOutlined style={{ fontSize: 18 }} />}
          className="menu__item"
        >
          <Link to="/bop" className="nav-text" onClick={toggleMenu}>
            BOP
          </Link>
        </Menu.Item>

        {/* <Can type="admin_view" key="/notifications"> */}
        {/*  <Menu.Item */}
        {/*    icon={<MailOutlined style={{ fontSize: 18 }} />} */}
        {/*    className="menu__item" */}
        {/*    disabled */}
        {/*  > */}
        {/*    <Link to="/notifications" className="nav-text" onClick={toggleMenu}> */}
        {/*      Powiadomienia */}
        {/*    </Link> */}
        {/*  </Menu.Item> */}
        {/* </Can> */}
      </Menu>
    </Sider>
  );
};

export default SideMenu;
