import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import {
  DatePicker,
  Form,
  Input,
  Select,
  SubmitButton,
  Switch,
} from "formik-antd";
import {
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Row,
  Space,
  Spin,
  Upload,
} from "antd";
import { Formik, FormikErrors, FormikProps, FormikValues } from "formik";
import * as Yup from "yup";

import moment, { Moment } from "moment";
import Text from "antd/es/typography/Text";
import { PaperClipOutlined } from "@ant-design/icons";
import { UploadChangeParam } from "antd/lib/upload/interface";

import { Priority, PriorityColor } from "../../../../../types/ticket-group";
import TicketService from "../../../../../services/tickets-service";
import UploadAttachmentsList from "../../../../Shared/UplaodAttachmentsList";
import Can from "../../../../Shared/Can";
import QuillEditor from "../../../../Shared/Editor";
import BopService from "../../../../../services/bop-service";

export interface IBopBasicFormValues {
  title: string;

  description: string;

  attachments: any[];
}

export interface IBopProps {
  title?: string;
  isLoading: boolean;
  description?: string;
  onSave: (
    values: IBopBasicFormValues,
    onRequestComplete: () => void,
    setError: (errors: FormikErrors<IBopBasicFormValues>) => void
  ) => void;
}

const disabledDate = (current: Moment) => {
  return current && current < moment().startOf("day");
};

const { Option, OptGroup } = Select;
const TicketBasicForm = (props: IBopProps) => {
  const {
    title,

    description,

    isLoading,

    onSave,
  } = props;
  // TODO: managerID should be relation instead of integer
  const initialFormValues: IBopBasicFormValues = {
    title: "",
    description: "",
    attachments: [],
  };
  const [formData, setFormData] = useState<IBopBasicFormValues>(
    initialFormValues
  );

  const [editRequest, setEditRequest] = useState<boolean>(false);

  const [uploadList, setUploadList] = useState<any>([]);

  const dueDateRequired = !!Can({
    type: "due_date_required",
    children: <></>,
  });

  const handleAttachmentProgressChange = (file: any, progress: number) => {
    setUploadList((prevState: any) => {
      const idx = prevState.findIndex((item: any) => item.id === file.uid);
      if (idx > -1) {
        const copyItem = { ...prevState[idx] };
        copyItem.progress = progress;
        return [
          ...prevState.slice(0, idx),
          copyItem,
          ...prevState.slice(idx + 1),
        ];
      }
      return null;
    });
  };

  const handleAttachmentsRemove = (file: any) => {
    setUploadList((prevState: any) => {
      const idx = prevState.findIndex((item: any) => item.id === file.id);
      if (idx > -1) {
        return [...prevState.slice(0, idx), ...prevState.slice(idx + 1)];
      }
      return null;
    });
  };

  const handleAttachmentsChange = (info: UploadChangeParam) => {
    const { file, fileList } = info;
    const item = {
      date: moment().unix(),
      fileMimeType: file.type,
      fileName: file.name,
      fileOriginalName: file.name,
      fileSize: file.size,
      progress: 0,
      hidden: false,
      id: file.uid,
      ticket: {},
      user: {},
      status: file.status,
    };

    setUploadList((prevState: any) => {
      return [item, ...prevState];
    });
  };

  const handleAttachmentsUpload = (options: any) => {
    // setIsUploading(true);
    return BopService.uploadAttachment(options.file, (change) =>
      handleAttachmentProgressChange(options.file, change)
    )
      .then((response) => {
        setUploadList((prevState: any) => {
          const idx = prevState.findIndex(
            (item: any) => item.id === options.file.uid
          );

          if (idx > -1) {
            return [
              ...prevState.slice(0, idx),
              response.data,
              ...prevState.slice(idx + 1),
            ];
          }

          return null;
        });
      })
      .catch((err) => {
        setUploadList((prevState: any) => {
          const idx = prevState.findIndex(
            (item: any) => item.id === options.file.uid
          );

          const copyItem = { ...prevState[idx] };
          copyItem.progress = 0;
          copyItem.status = "error";

          if (idx > -1) {
            return [
              ...prevState.slice(0, idx),
              copyItem,
              ...prevState.slice(idx + 1),
            ];
          }

          return null;
        });
      });
  };

  const formikRef = useRef<FormikValues>();

  const handleSubmit = () => {
    formikRef.current?.submitForm();
  };

  const FormSchema = Yup.object().shape({
    title: Yup.string().required("Pole wymagane"),
    description: Yup.string().required("Pole wymagane"),
    // assignedUsers: Yup.array().min(
    //   1,
    //   "Zadanie powinno być przypisane do min. jednej osoby."
    // ),
  });

  return (
    <Spin spinning={isLoading}>
      <Card title="Podstawowe informacje">
        <Formik
          innerRef={formikRef as MutableRefObject<any>}
          initialValues={formData}
          enableReinitialize
          validationSchema={FormSchema}
          onSubmit={(values: IBopBasicFormValues, { resetForm, setErrors }) => {
            setEditRequest(true);
            // console.log(assignedFormRef.current, "??");
            // assignedFormRef.current?.submitForm();
            onSave(
              {
                ...values,
                attachments: uploadList
                  .filter(
                    (file: any) =>
                      file.status !== "error" || file.status === "uploading"
                  )
                  .map((file: any) => file.id),
              },
              () => setEditRequest(false),
              setErrors
            );
          }}
          validateOnChange
          render={(formProps) => (
            <Form>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="Tytuł"
                    required
                    name="title"
                    labelCol={{ span: 24 }}
                    rules={[{ required: true }]}
                  >
                    <Input name="title" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Opis"
                    required
                    name="description"
                    labelCol={{ span: 24 }}
                    rules={[{ required: true }]}
                  >
                    <QuillEditor
                      initValue={initialFormValues.description}
                      value={formProps.values.description}
                      withMedia
                      reinitialize={false}
                      onChange={(change: string) => {
                        const val = change === "<p><br></p>" ? "" : change;
                        formProps.setFieldValue("description", val);
                        formProps.setFieldTouched("description", true);
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <h3 style={{ marginTop: 16 }}>Załączniki</h3>

                      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                      <Row align="middle" justify="space-between">
                        <Col sm={24} md={24} lg={24}>
                          <UploadAttachmentsList
                            displayHeader
                            attachments={uploadList}
                            handleRemove={handleAttachmentsRemove}
                          />
                        </Col>
                      </Row>
                      <Row
                        align="middle"
                        justify="space-between"
                        gutter={[0, 16]}
                      >
                        <Col sm={24} md={24} lg={24}>
                          <Upload
                            multiple
                            listType="text"
                            showUploadList={false}
                            customRequest={handleAttachmentsUpload}
                            onChange={handleAttachmentsChange}
                          >
                            <Button
                              icon={<PaperClipOutlined />}
                              style={{ marginTop: 16, marginBottom: 24 }}
                            >
                              Dodaj załącznik
                            </Button>
                          </Upload>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Button
                    type="primary"
                    onClick={handleSubmit}
                    loading={editRequest}
                    disabled={
                      !formProps.isValid ||
                      (uploadList &&
                        uploadList.findIndex(
                          (attachment: any) => attachment.status === "uploading"
                        ) !== -1)
                    }
                  >
                    Zapisz
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        />
      </Card>
    </Spin>
  );
};

export default React.memo(TicketBasicForm);
