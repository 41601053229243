import React, { Ref, useEffect, useState } from "react";
// eslint-disable react/destructuring-assignment
import {
  Alert,
  Avatar,
  Badge,
  Button,
  Col,
  Comment,
  Empty,
  Form,
  List,
  Row,
  Skeleton,
  Slider,
  Space,
  Tooltip,
  Upload,
} from "antd";
import moment from "moment";
import {
  ArrowRightOutlined,
  CloudDownloadOutlined,
  DownloadOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  FullscreenOutlined,
  IssuesCloseOutlined,
  LoadingOutlined,
  MailOutlined,
  PaperClipOutlined,
  StopOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import HTMLReactParser from "html-react-parser";
import Text from "antd/lib/typography/Text";
import { UploadChangeParam } from "antd/lib/upload/interface";
import FileDownload from "js-file-download";
import _ from "lodash";
import Lightbox from "react-image-lightbox";
import classnames from "classnames";
import QuillEditor from "../../Shared/Editor";
import { AccountType } from "../../../types/user";
import {
  defaultEditorFormats,
  defaultEditorModules,
  formatMoney,
  getMobileOperatingSystem,
  isImageFileType,
  makeAvatarName,
  stringToColour,
} from "../../../utils/common";
import TicketService from "../../../services/tickets-service";
import UploadAttachmentsList from "../../Shared/UplaodAttachmentsList";
import Can from "../../Shared/Can";
import useKeyPress from "../../../hooks/useKeyPress";
import jobinAvatar from "../../../assets/images/jobin_avatar.png";

interface User {
  name: string;
  surname: string;
  image: string | null;
  typeAccount: AccountType;
  avatarColor: string;
  avatarBackground: string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {
  isMobile: boolean;
  isSticky: boolean;
  commentRef: any;
  loggedUser: any;
  comments: any;
  ticket: any;
  linkId: string | null;
  isFetching: boolean;
  scratchpad: {
    comment: any;
  };
  isSubmitting: boolean;
  handleSubmit: (
    comment: any,
    reloadTicket: boolean,
    leaveTicket: boolean
  ) => Promise<any>;
  handleAutoSave: (comment: any) => Promise<any>;
  toggleCommentsExpand: () => void;
  expanded: boolean;
};

type State = {
  comments: any[];
  submitting: boolean;
  autoSaving: boolean;
  value: any;
  sendSms: boolean;
  progress: number;
  closeTicket: boolean;
  leaveTicket: boolean;
  attachments: any[];
  reloadTicket: boolean;
  hideAttachments: boolean;
  clear: number;
  photoIndex: number;
  previewVisible: boolean;
  images: string[];
  downloadId: number | null;
  previewId: number | null;
  scrolledToLink: string | null;
};

const CommentList = ({ comments, isFetching }: any) => (
  <Space
    direction="vertical"
    size="small"
    style={{ margin: "32px 0 0", width: "100%" }}
  >
    <Row justify="space-between" align="middle">
      <Col>
        <h4>Komentarze {comments.length > 0 && `(${comments.length})`}</h4>
      </Col>
    </Row>
    <Row justify="space-between" align="middle">
      <Col span={24}>
        {isFetching && !comments.length && <Skeleton />}
        {!isFetching && (
          <List
            locale={{
              emptyText: <Empty description="Brak komentarzy" />,
            }}
            dataSource={comments}
            bordered={false}
            style={{ borderBottomStyle: "none", width: "100%" }}
            header={null}
            itemLayout="horizontal"
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            renderItem={(props: any) => <Comment {...props} />}
          />
        )}
      </Col>
    </Row>
  </Space>
);

const Editor = ({
  attachments,
  displayAlternativeExpandIcon,
  handleAttachmentRemove,
  handleAttachmentChange,
  handleAttachmentUpload,
  onChange,
  onProgressChange,
  toggleSendSms,
  toggleHideAttachments,
  toggleCloseTicket,
  toggleLeaveTicket,
  onSubmit,
  autoSaving,
  submitting,
  value,
  hidden,
  sendSms,
  progress,
  ticket,
  closeTicket,
  leaveTicket,
  hideAttachments,
  commentRef,
  hiddenRef,
  scratchpad,
  clear,
  isMobile,
  expanded,
  toggleCommentsExpand,
}: any) => {
  const formats = ["code"];
  useKeyPress(
    "ctrl",
    ["enter"],
    () => {
      if (
        (!value && !hidden) ||
        (attachments &&
          attachments.findIndex(
            (attachment: any) => attachment.status === "uploading"
          ) !== -1) ||
        autoSaving ||
        submitting
      ) {
        console.log("cannot save");
      } else {
        onSubmit();
      }
    },
    document.querySelector(".comment-editor")?.getRootNode()
  );
  const canDisplayHiddenCommentsAndAttachments = Can({
    entity: ticket,
    type: "ticket_hidden_comments",
    children: <></>,
  });

  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={!isMobile && expanded ? 24 : 12}>
          <Form.Item
            className="comment-editor"
            label={
              <>
                <Space style={{ height: 24 }}>
                  <Text>Nowy komentarz</Text>
                  {autoSaving && value && (
                    <>
                      <LoadingOutlined className="autosave--icon" spin />
                      <small className="autosave--text">Autozapis</small>
                    </>
                  )}
                </Space>
                <Tooltip title="Rozszerz okno komentarzy">
                  <Button
                    style={{ marginLeft: "auto" }}
                    size="small"
                    icon={
                      displayAlternativeExpandIcon ? (
                        <UploadOutlined
                          style={{ transform: "rotate(180deg)" }}
                        />
                      ) : (
                        <FullscreenOutlined />
                      )
                    }
                    type={expanded ? "primary" : "default"}
                    onClick={toggleCommentsExpand}
                  />
                </Tooltip>
              </>
            }
            labelCol={{ span: 24 }}
          >
            <QuillEditor
              value={value}
              ref={commentRef}
              className={classnames({ expanded })}
              withMedia
              initValue={scratchpad?.comment?.content || ""}
              onChange={onChange}
              clear={clear}
            />
          </Form.Item>
        </Col>
      </Row>
      {attachments.length > 0 && (
        <Row align="middle" justify="space-between">
          <Col sm={24} md={24} lg={24}>
            <UploadAttachmentsList
              displayHeader
              attachments={attachments}
              handleRemove={handleAttachmentRemove}
            />
          </Col>
        </Row>
      )}
      <Row align="middle" justify="space-between">
        <Col sm={24} md={24} lg={!isMobile && expanded ? 24 : 12}>
          <Row align="middle" justify="space-between" gutter={[16, 0]}>
            <Col>
              <Form.Item>
                <Button
                  disabled={
                    (!value && !hidden) ||
                    (attachments &&
                      attachments.findIndex(
                        (attachment: any) => attachment.status === "uploading"
                      ) !== -1) ||
                    autoSaving
                  }
                  htmlType="submit"
                  loading={submitting}
                  onClick={onSubmit}
                  style={{ minWidth: 140 }}
                  type="primary"
                >
                  {autoSaving ? (
                    <Space>
                      <LoadingOutlined spin />
                      <span>Autozapis</span>
                    </Space>
                  ) : (
                    "Dodaj komentarz"
                  )}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Space align="end" style={{ justifyContent: "flex-end" }}>
                  <Tooltip title="Dodaj załącznik">
                    <Upload
                      multiple
                      listType="text"
                      showUploadList={false}
                      customRequest={handleAttachmentUpload}
                      onChange={handleAttachmentChange}
                    >
                      <Button icon={<PaperClipOutlined />} />
                    </Upload>
                  </Tooltip>

                  <Can type="bop_close" entity={ticket}>
                    <Tooltip title="Zamknij zgłoszenie">
                      <Button
                        icon={
                          <IssuesCloseOutlined
                            style={{ color: closeTicket ? "white" : "red" }}
                          />
                        }
                        type={closeTicket ? "primary" : "default"}
                        onClick={toggleCloseTicket}
                      />
                    </Tooltip>
                  </Can>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <Can type="ticket_edit_progress" entity={ticket}> */}
      {/*  <Form.Item */}
      {/*    label="Postęp" */}
      {/*    style={{ */}
      {/*      justifyContent: "center", */}
      {/*      alignItems: "center", */}
      {/*      paddingLeft: 8, */}
      {/*      paddingRight: 8, */}
      {/*    }} */}
      {/*    labelCol={isMobile ? { xs: 24, sm: 24 } : {}} */}
      {/*  > */}
      {/*    <Slider value={progress} onChange={onProgressChange} /> */}
      {/*  </Form.Item> */}
      {/* </Can> */}
    </Space>
  );
};

class Comments extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      comments: this.parseComments(props.comments, props.isMobile),
      submitting: false,
      autoSaving: false,
      value: props.scratchpad?.comment?.content || "",
      sendSms: false,
      progress: props.ticket?.progress,
      closeTicket: false,
      leaveTicket: false,
      hideAttachments: false,
      reloadTicket: false,
      attachments: [],
      clear: 0,
      images: [],
      previewVisible: false,
      photoIndex: 0,
      previewId: null,
      downloadId: null,
      scrolledToLink: null,
    };
    this.renderHistoryValue = this.renderHistoryValue.bind(this);
    this.parseComments = this.parseComments.bind(this);
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    const {
      loggedUser,
      comments,
      ticket,
      scratchpad,
      isMobile,
      isSticky,
      linkId,
    } = this.props;
    const { previewId, downloadId, scrolledToLink } = this.state;

    if (prevProps.comments !== comments) {
      const parsedComments = this.parseComments(comments, isMobile);
      this.updateComments(parsedComments);
    }

    if (
      prevState.previewId !== previewId ||
      prevState.downloadId !== downloadId
    ) {
      const parsedComments = this.parseComments(comments, isMobile);
      this.updateComments(parsedComments);
    }

    if (prevProps.ticket?.progress !== ticket?.progress) {
      this.updateProgress(ticket?.progress);
    }

    if (
      prevProps.scratchpad?.comment?.content !== scratchpad?.comment?.content
    ) {
      this.updateScratchPad(scratchpad?.comment?.content || "");
    }

    if (linkId && scrolledToLink !== linkId) {
      requestAnimationFrame(() => {
        this.scrollToLinkedComment();
      });
    }
  }

  onAutoSaveDebounce = _.debounce(() => this.onAutoSave(), 1000);

  scrollToLinkedComment = () => {
    const { linkId } = this.props;

    if (!linkId) return;

    const linkedElement = document.getElementById(linkId);
    const commentElement = linkedElement?.closest(".ant-comment");
    if (!commentElement) return;

    this.setActiveLinkedComment(commentElement);
    setTimeout(() => {
      requestAnimationFrame(() => {
        const platform = getMobileOperatingSystem();
        if (platform === "iOS") commentElement.scrollIntoView(true);
        else
          commentElement.scrollIntoView({
            block: "center",
          });

        this.updateScrolledToLink(linkId);
      });
    }, 50);
  };

  setActiveLinkedComment = (element: Element) => {
    element.classList.add("ant-comment--linked");
  };

  updateComments = (parsedComments: any[]) => {
    this.setState({ comments: parsedComments });
  };

  updateScrolledToLink = (link: string) => {
    this.setState({ scrolledToLink: link });
  };

  updateProgress = (progress: number) => {
    this.setState({ progress });
  };

  updateScratchPad = (value: string) => {
    this.setState({ value });
  };

  toggleCloseTicket = () => {
    const { closeTicket } = this.state;
    this.setState({
      closeTicket: !closeTicket,
      reloadTicket: true,
    });
  };

  toggleLeaveTicket = () => {
    const { leaveTicket } = this.state;
    this.setState({
      leaveTicket: !leaveTicket,
      reloadTicket: true,
    });
  };

  toggleHideAttachments = () => {
    const { hideAttachments } = this.state;
    this.setState({
      hideAttachments: !hideAttachments,
    });
  };

  toggleSendSms = () => {
    const { sendSms } = this.state;
    this.setState({
      sendSms: !sendSms,
    });
  };

  renderHistoryKey = (entry: any) => {
    switch (entry.type) {
      case "title":
        return (
          <Space>
            <strong>Tytuł:</strong>
          </Space>
        );
      case "attachment":
        return (
          <Space>
            <strong>Załącznik:</strong>
          </Space>
        );
      case "hidden_attachment":
        return (
          <>
            <Tooltip title="Załącznik ukryty">
              <EyeInvisibleOutlined className="ticket-history__icon" />
            </Tooltip>
            <Space>
              <strong>Załącznik:</strong>
            </Space>
          </>
        );
      case "status":
        return <strong>Status:</strong>;
      case "valuation_status":
        return <strong>Status wyceny:</strong>;
      case "progress":
        return <strong>Postęp:</strong>;
      case "sms":
        return <strong>Wiadomość SMS:</strong>;
        break;
      case "estimation":
        return <strong>Estymacja:</strong>;
        break;
      case "favorite":
        return <strong>Wyróżnij:</strong>;
        break;
      case "highlight":
        return <strong>Przypnij do góry:</strong>;
        break;
      case "callclient":
        return <strong>Zadzwoń:</strong>;
        break;
      case "priority":
        return <strong>Priorytet:</strong>;
        break;
      case "due_date":
        return <strong>Termin:</strong>;
        break;
      case "due_date_show":
        return <strong>Termin na stronie głównej:</strong>;
        break;
      case "paid":
        return <strong>Płatne:</strong>;
        break;
      case "cost":
        return <strong>Wycena:</strong>;
        break;
      case "forward":
        return <strong>Przekazano:</strong>;
        break;
      case "assigned":
        return <strong>Zmiana przydzielenia:</strong>;
        break;
      default:
        return entry.type;
    }
  };

  renderHistoryValue = (entry: any) => {
    const { previewId, downloadId } = this.state || {
      previewId: 0,
      downloadId: 0,
    };
    switch (entry.type) {
      case "title":
        return (
          <Space size="small">
            <span className="ticket-history__value--old">{entry.old}</span>
            <ArrowRightOutlined />
            <span className="ticket-history__value--new">{entry.new}</span>
          </Space>
        );
      case "attachment":
        return (
          <Space style={{ width: "100%", justifyContent: "flex-end" }}>
            <Text
              onClick={() =>
                isImageFileType(entry.new.fileMimeType)
                  ? this.handlePreview(entry.new)
                  : this.handleDownload(entry.new)
              }
              className="ticket-history ticket-history__link"
            >
              {entry.new.name}
            </Text>
            {isImageFileType(entry.new.fileMimeType) && (
              <Tooltip title="Podejrzyj załacznik">
                <Button
                  size="small"
                  icon={
                    entry.new.id === previewId ? (
                      <LoadingOutlined spin />
                    ) : (
                      <EyeOutlined />
                    )
                  }
                  onClick={() => this.handlePreview(entry.new)} // handlePReview
                />
              </Tooltip>
            )}
            <Tooltip title="Pobierz załacznik">
              <Button
                size="small"
                icon={
                  entry.id === downloadId ? (
                    <LoadingOutlined spin />
                  ) : (
                    <CloudDownloadOutlined />
                  )
                }
                onClick={() => this.handleDownload(entry.new)}
              />
            </Tooltip>
          </Space>
        );
      case "hidden_attachment":
        return (
          <Space style={{ width: "100%", justifyContent: "flex-end" }}>
            <Text
              onClick={() =>
                isImageFileType(entry.new.fileMimeType)
                  ? this.handlePreview(entry.new)
                  : this.handleDownload(entry.new)
              }
              className="ticket-history ticket-history__link"
            >
              {entry.new.name}
            </Text>
            {isImageFileType(entry.new.fileMimeType) && (
              <Tooltip title="Podejrzyj załacznik">
                <Button
                  size="small"
                  icon={
                    entry.new.id === previewId ? (
                      <LoadingOutlined spin />
                    ) : (
                      <EyeOutlined />
                    )
                  }
                  onClick={() => this.handlePreview(entry.new)} // handlePReview
                />
              </Tooltip>
            )}
            <Tooltip title="Pobierz załacznik">
              <Button
                size="small"
                icon={
                  entry.id === downloadId ? (
                    <LoadingOutlined spin />
                  ) : (
                    <CloudDownloadOutlined />
                  )
                }
                onClick={() => this.handleDownload(entry.new)}
              />
            </Tooltip>
          </Space>
        );
      case "status":
        return (
          <Space size="small">
            <span className="ticket-history__value--old">{entry.old.name}</span>
            <ArrowRightOutlined />
            <span className="ticket-history__value--new">{entry.new.name}</span>
          </Space>
        );
      case "valuation_status":
        return (
          <Space size="small">
            <span className="ticket-history__value--old">
              {entry.old ? entry.old.name : "Brak"}
            </span>
            <ArrowRightOutlined />
            <span className="ticket-history__value--new">
              {entry.new ? entry.new.name : "Brak"}
            </span>
          </Space>
        );
      case "progress":
        return (
          <Space size="small">
            <span className="ticket-history__value--old">
              {entry.old || 0}%
            </span>
            <ArrowRightOutlined />
            <span className="ticket-history__value--new">{entry.new}%</span>
          </Space>
        );
      case "sms":
        return (
          <Space size="small">
            <span className="ticket-history__value--new">{entry.new}</span>
          </Space>
        );
      case "estimation":
        return (
          <Space size="small">
            <span className="ticket-history__value--old">
              {entry.old || 0}h
            </span>
            <ArrowRightOutlined />
            <span className="ticket-history__value--new">
              {entry.new || 0}h
            </span>
          </Space>
        );
      case "favorite":
        return (
          <Space size="small">
            <span className="ticket-history__value--old">
              {entry.old === "0" ? "Wyłączono" : "Włączono"}
            </span>
            <ArrowRightOutlined />
            <span className="ticket-history__value--new">
              {entry.new === "0" ? "Wyłączono" : "Włączono"}
            </span>
          </Space>
        );
      case "callclient":
        return (
          <Space size="small">
            <span className="ticket-history__value--old">
              {entry.old === "0" ? "Wyłączono" : "Włączono"}
            </span>
            <ArrowRightOutlined />
            <span className="ticket-history__value--new">
              {entry.new === "0" ? "Wyłączono" : "Włączono"}
            </span>
          </Space>
        );
      case "highlight":
        return (
          <Space size="small">
            <span className="ticket-history__value--old">
              {entry.old === "0" ? "Wyłączono" : "Włączono"}
            </span>
            <ArrowRightOutlined />
            <span className="ticket-history__value--new">
              {entry.new === "0" ? "Wyłączono" : "Włączono"}
            </span>
          </Space>
        );
      case "priority":
        return (
          <Space size="small">
            <span className="ticket-history__value--old">{entry.old}</span>
            <ArrowRightOutlined />
            <span className="ticket-history__value--new">{entry.new}</span>
          </Space>
        );
      case "due_date":
        return (
          <Space size="small">
            <span className="ticket-history__value--old">
              {entry.old
                ? moment(entry.old * 1000).format("DD.MM.Y HH:mm")
                : "Brak"}
            </span>
            <ArrowRightOutlined />
            <span className="ticket-history__value--new">
              {entry.new
                ? moment(entry.new * 1000).format("DD.MM.Y HH:mm")
                : "Brak"}
            </span>
          </Space>
        );
      case "due_date_show":
        return (
          <Space size="small">
            <span className="ticket-history__value--old">
              {entry.old === "0" ? "Wyłączono" : "Włączono"}
            </span>
            <ArrowRightOutlined />
            <span className="ticket-history__value--new">
              {" "}
              {entry.new === "0" ? "Wyłączono" : "Włączono"}
            </span>
          </Space>
        );
      case "paid":
        return (
          <Space size="small">
            <span className="ticket-history__value--old">
              {entry.old ? "Tak" : "Nie"}
            </span>
            <ArrowRightOutlined />
            <span className="ticket-history__value--new">
              {entry.new ? "Tak" : "Nie"}
            </span>
          </Space>
        );
      case "cost":
        return (
          <Space size="small">
            <span className="ticket-history__value--old">
              {entry.old ? formatMoney(parseFloat(entry.old)) : "0.00 zł"}
            </span>
            <ArrowRightOutlined />
            <span className="ticket-history__value--new">
              {entry.new ? formatMoney(parseFloat(entry.new)) : "0.00 zł"}
            </span>
          </Space>
        );
      case "forward":
        return (
          <Space size="small">
            <span className="ticket-history__value--old">
              {entry.old || "Brak"}
            </span>
            <ArrowRightOutlined />
            <span className="ticket-history__value--new">
              {entry.new || "Brak"}
            </span>
          </Space>
        );
      case "assigned":
        return (
          <Space size="small">
            <span className="ticket-history__value--old">
              {entry.old.length
                ? entry.old.map((user: any) => user.name).join(", ")
                : "Brak"}
            </span>
            <ArrowRightOutlined />
            <span className="ticket-history__value--new">
              {entry.new.length
                ? entry.new.map((user: any) => user.name).join(", ")
                : "Brak"}
            </span>
          </Space>
        );
      default:
        return null;
    }
  };

  parseComments = (comments: any[], isMobile: boolean) => {
    return comments.map((comment: any) => {
      const avatarImg = comment.user?.email ? comment.user?.img : jobinAvatar;
      return {
        author: (
          <Space>
            {comment.isNew && (
              <Badge
                count="Nowy"
                size="default"
                style={{
                  backgroundColor: "crimson",
                  marginLeft: 2,
                  marginBottom: 2,
                }}
              />
            )}
            <Text>
              {comment.user
                ? `${comment.user.name} ${comment.user.surname}`
                : "jobin"}
            </Text>
          </Space>
        ),
        avatar: (
          <Avatar
            size={isMobile ? 26 : 40}
            src={avatarImg || null}
            className="user-app__avatar"
            style={{
              color: "#fefefe",
              backgroundColor: stringToColour(comment.user?.email || "jobin"),
              cursor: "pointer",
            }}
          >
            {makeAvatarName(
              comment.user?.deleted || false,
              comment.user?.name || "jobin",
              comment.user?.surname
            )}
          </Avatar>
        ),
        content: (
          <>
            {comment.history && (
              <>
                <Space
                  direction="vertical"
                  className="ticket-history__row"
                  size="small"
                  style={{ width: "100%", gap: 0, rowGap: 0 }}
                >
                  {comment.history.map((entry: any) => (
                    <Row
                      gutter={[0, 0]}
                      style={{ width: "100%" }}
                      className={classnames({
                        "ticket-history__entry": true,
                        "ticket-history__entry--hidden":
                          entry.type === "hidden_attachment",
                      })}
                      align="middle"
                      id={`event-${entry.id}`}
                    >
                      <Col
                        xs={24}
                        sm={24}
                        md={4}
                        lg={3}
                        style={{
                          display: "flex",

                          alignItems: "center",
                        }}
                      >
                        {this.renderHistoryKey(entry)}
                      </Col>
                      <Col span="auto">
                        <span>{this.renderHistoryValue(entry)}</span>
                      </Col>
                    </Row>
                  ))}
                </Space>
              </>
            )}
            <Space direction="vertical" size="small" style={{ paddingLeft: 4 }}>
              {comment.comment && (
                <div id={`comment-${comment.commentId}`}>
                  {HTMLReactParser(comment.comment || "")}
                </div>
              )}

              {comment.hidden && (
                <div id={`hidden-${comment.hiddenId}`}>
                  <Alert
                    banner
                    message={HTMLReactParser(comment.hidden || "")}
                    type="warning"
                    icon={
                      <Tooltip title="Komentarz ukryty">
                        <EyeInvisibleOutlined />
                      </Tooltip>
                    }
                  />
                </div>
              )}
            </Space>
          </>
        ),
        datetime: (
          <Tooltip title={moment(comment.date * 1000).format("DD.MM.Y HH:mm")}>
            {moment(comment.date * 1000).fromNow()}
          </Tooltip>
        ),
      };
    });
  };

  getImageUrl = async (fileId: any) => {
    const response = await TicketService.previewFile(fileId);
    return response;
  };

  handleDownload = (file: any) => {
    this.setState({ downloadId: file.id });
    TicketService.downloadFile(file.id).then((response) => {
      this.setState({ downloadId: null });
      FileDownload(response.data, file.name);
    });
  };

  handlePreview = async (file: any) => {
    this.setState({ previewId: file.id });
    const image = await this.getImageUrl(file.id);
    this.setState({ images: [image] });
    this.setState({ previewVisible: true, previewId: null });
  };

  handleSubmit = () => {
    const {
      progress,
      value,
      sendSms,
      leaveTicket,
      closeTicket,
      attachments,
      hideAttachments,
      reloadTicket,
    } = this.state;
    const { loggedUser, handleSubmit } = this.props;

    console.log(value, "submit");
    if (!value) {
      return;
    }
    console.log(value, "submit");
    this.setState({
      submitting: true,
    });

    handleSubmit(
      {
        value,
        attachments: attachments
          .filter(
            (file) => file.status !== "error" || file.status === "uploading"
          )
          .map((file) => file.id),
        hideAttachments,
        closeTicket,
        leaveTicket,
        sendSms,
        progress,
      },
      reloadTicket,
      leaveTicket
    )
      .then((res) => {
        this.setState({
          submitting: false,
          attachments: [],
          hideAttachments: false,
          closeTicket: false,
          leaveTicket: false,
          sendSms: false,
          reloadTicket: false,
          clear: new Date().getTime(),
          value: "",
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          submitting: false,
        });
      });
  };

  onAutoSave = () => {
    const {
      progress,
      value,
      sendSms,
      closeTicket,
      leaveTicket,
      attachments,
      hideAttachments,
      reloadTicket,
    } = this.state;
    const { loggedUser, handleAutoSave } = this.props;
    // if (!value && !hidden) {
    //   return;
    // }

    this.setState({
      autoSaving: true,
    });

    handleAutoSave({
      value,
    }).finally(() => {
      this.setState({
        autoSaving: false,
      });
    });
  };

  handleChange = (val: any) => {
    const { value } = this.state;
    const { scratchpad } = this.props;

    const newValue = val === "<p><br></p>" ? "" : val;
    this.setState({
      value: newValue,
    });

    if (newValue !== scratchpad.comment?.content) this.onAutoSaveDebounce();
  };

  // handleChangeHidden = (value: any) => {
  //   const newValue = value === "<p><br></p>" ? "" : value;
  //   this.setState({
  //     hidden: newValue,
  //   });
  //
  //   const { scratchpad } = this.props;
  //
  //   if (newValue !== scratchpad.hidden?.contentHide) this.onAutoSaveDebounce();
  // };

  handleProgressChange = (value: any) => {
    this.setState({
      progress: value,
      reloadTicket: true,
    });
  };

  handleAttachmentProgressChange = (file: any, progress: number) => {
    this.setState((prevState) => {
      const idx = prevState.attachments.findIndex(
        (item: any) => item.id === file.uid
      );
      if (idx > -1) {
        const copyItem = { ...prevState.attachments[idx] };
        copyItem.progress = progress;
        return {
          ...prevState,
          attachments: [
            ...prevState.attachments.slice(0, idx),
            copyItem,
            ...prevState.attachments.slice(idx + 1),
          ],
        };
      }
      return null;
    });
  };

  handleAttachmentsRemove = (file: any) => {
    this.setState((prevState) => {
      const idx = prevState.attachments.findIndex(
        (item: any) => item.id === file.id
      );
      if (idx > -1) {
        return {
          ...prevState,
          attachments: [
            ...prevState.attachments.slice(0, idx),
            ...prevState.attachments.slice(idx + 1),
          ],
        };
      }
      return null;
    });
  };

  handleAttachmentsChange = (info: UploadChangeParam) => {
    const { file, fileList } = info;
    const item = {
      date: moment().unix(),
      fileMimeType: file.type,
      fileName: file.name,
      fileOriginalName: file.name,
      fileSize: file.size,
      progress: 0,
      hidden: false,
      id: file.uid,
      ticket: {},
      user: {},
      status: file.status,
    };

    this.setState((prevState) => {
      return {
        ...prevState,
        reloadTicket: true,
        attachments: [item, ...prevState.attachments],
      };
    });
  };

  handleAttachmentsUpload = (options: any) => {
    // setIsUploading(true);
    return TicketService.uploadAttachment(options.file, (change) =>
      this.handleAttachmentProgressChange(options.file, change)
    )
      .then((response) => {
        this.setState((prevState) => {
          const idx = prevState.attachments.findIndex(
            (item: any) => item.id === options.file.uid
          );

          if (idx > -1) {
            return {
              ...prevState,
              attachments: [
                ...prevState.attachments.slice(0, idx),
                response.data,
                ...prevState.attachments.slice(idx + 1),
              ],
            };
          }

          return null;
        });
      })
      .catch((err) => {
        this.setState((prevState) => {
          const idx = prevState.attachments.findIndex(
            (item: any) => item.id === options.file.uid
          );

          const copyItem = { ...prevState.attachments[idx] };
          copyItem.progress = 0;
          copyItem.status = "error";

          if (idx > -1) {
            return {
              ...prevState,
              attachments: [
                ...prevState.attachments.slice(0, idx),
                copyItem,
                ...prevState.attachments.slice(idx + 1),
              ],
            };
          }

          return null;
        });
      });
  };

  render() {
    const {
      attachments,
      hideAttachments,
      clear,
      autoSaving,
      comments,
      submitting,
      value,
      closeTicket,
      sendSms,
      progress,
      leaveTicket,
      previewVisible,
      images,
      previewId,
      downloadId,
      photoIndex,
    } = this.state;
    const {
      loggedUser,
      ticket,
      isFetching,
      commentRef,
      scratchpad,
      isMobile,
      isSticky,
      expanded,
      toggleCommentsExpand,
    } = this.props;
    return (
      <>
        <CommentList comments={comments} isFetching={isFetching} />

        <Can entity={ticket} type="bop_comment">
          <div style={{ borderTop: "1px solid #eee" }} id="add-comments">
            <Comment
              avatar={
                !isMobile ? (
                  <Avatar
                    size={40}
                    src={loggedUser.img || null}
                    className="user-app__avatars"
                    style={{
                      color: "#fefefe",
                      backgroundColor: stringToColour(loggedUser.email),
                    }}
                  >
                    {makeAvatarName(false, loggedUser.name, loggedUser.surname)}
                  </Avatar>
                ) : null
              }
              content={
                <Editor
                  isMobile={isMobile}
                  scratchpad={scratchpad}
                  commentRef={commentRef}
                  user={loggedUser}
                  onChange={this.handleChange}
                  toggleCloseTicket={this.toggleCloseTicket}
                  toggleLeaveTicket={this.toggleLeaveTicket}
                  toggleSendSms={this.toggleSendSms}
                  toggleHideAttachments={this.toggleHideAttachments}
                  onSubmit={this.handleSubmit}
                  submitting={submitting}
                  autoSaving={autoSaving}
                  attachments={attachments}
                  value={value}
                  expanded={expanded}
                  toggleCommentsExpand={toggleCommentsExpand}
                  displayAlternativeExpandIcon={isMobile || !isSticky}
                  clear={clear}
                  closeTicket={closeTicket}
                  leaveTicket={leaveTicket}
                  sendSms={sendSms}
                  hideAttachments={hideAttachments}
                  onAttachmentProgressChange={
                    this.handleAttachmentProgressChange
                  }
                  handleAttachmentRemove={this.handleAttachmentsRemove}
                  handleAttachmentChange={this.handleAttachmentsChange}
                  handleAttachmentUpload={this.handleAttachmentsUpload}
                  ticket={ticket}
                  progress={progress}
                  onProgressChange={this.handleProgressChange}
                />
              }
            />
          </div>
        </Can>
        {previewVisible && (
          <Lightbox
            reactModalStyle={{ zIndex: 10000 }}
            mainSrc={images[photoIndex]}
            onCloseRequest={() => this.setState({ previewVisible: false })}
            onMovePrevRequest={() => this.setState({ photoIndex: 0 })}
            onMoveNextRequest={() => this.setState({ photoIndex: 0 })}
          />
        )}
      </>
    );
  }
}

export default Comments;
