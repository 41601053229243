/* eslint-disable react/destructuring-assignment */
import React, { PropsWithChildren, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Divider,
  Dropdown,
  Menu,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { Link } from "react-router-dom";
import Icon, {
  AccountBookFilled,
  BookFilled,
  BookOutlined,
  CarFilled,
  DollarCircleOutlined,
  ExceptionOutlined,
  FieldTimeOutlined,
  LockOutlined,
  PercentageOutlined,
  PhoneFilled,
  PhoneOutlined,
  SendOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";

import Text from "antd/es/typography/Text";
import moment from "moment";
import { Priority, PriorityColor } from "../../../types/ticket-group";
import TicketGroupUpdateFilter from "../../Shared/CustomFilters/TicketGroupUpdate";
import TicketType from "../../Shared/TicketType";

import { formatMoney, formatSeconds } from "../../../utils/common";
import UserOutlined, { UserOutlinedIcon } from "../../Shared/UserOutlined";
import UserFilled, { UserFilledIcon } from "../../Shared/UserFillled";
import TrimmedTitle from "../../Shared/TrimmedTitle";
import Can from "../../Shared/Can";
import { UserIcon } from "../../Shared/UserIcon";

interface ITicketType {
  id: number;
  name: string;
  color: string;
}
interface ITaskList {
  id: number;
  displayId: number;
  title: string;
  status: number;
  priority_id: {
    id: number;
  };
  progress: number;
  estimation: number;
  members: number;
  forwarded: number;
  lastUpdated: number;
  reporter: any;
  dueDate: any;
  cost: any;
  addDate: any;
  ticketType: ITicketType;
  pay: number;
  favorite: number;
  highlight: number;
  group: {
    id: number;
    leaders: any[];
    name: string;
  };
  callclient: number;
  newComments: number;
  isVisited: boolean;
  worktimeSeconds: number;
  assignedUsers: any[];
}
const TicketList = (props: PropsWithChildren<any>): JSX.Element => {
  const { tasks, isRequest, handleSubmitMiniForm, isMobile } = props;
  const [visiblePopover, setVisiblePopover] = useState<number | null>(null);

  const getCustomFilter = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <Row gutter={[16, 16]} style={{ padding: "8px" }}>
        <Col xs={24}>
          <TicketGroupUpdateFilter
            handleChange={(selected: any) => {
              setSelectedKeys([selected]);
              confirm();
            }}
            handleClear={clearFilters}
          />
        </Col>
      </Row>
    ),
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => console.log(visible), 100);
      }
    },
  });

  let columns = [
    {
      title: "Lista zgłoszeń",
      dataIndex: "title",
      className: "table__column",
      width: 100,
      key: "ticket",
      hidden: !isMobile,
      render: (title: string, bop: any) => {
        return (
          <Row>
            <Col xs={24}>
              <Space direction="vertical" className="ant-space-break-words">
                <Link
                  to={`/bop/${bop.id}`}
                  className="jb-link jb-link--secendary"
                >
                  <Space className="ant-space-break-words">
                    {bop?.status.id === 2 && (
                      <LockOutlined style={{ color: "red" }} />
                    )}
                    <span>
                      <TrimmedTitle title={`#${bop.id} - ${bop.title}`} />
                      <span
                        style={{
                          marginLeft: 6,
                        }}
                      >
                        {!bop.isVisited && (
                          <Badge
                            count="Nowy"
                            size="default"
                            style={{
                              backgroundColor: "crimson",
                              marginLeft: 2,
                              marginBottom: 2,
                            }}
                          />
                        )}
                        <Badge
                          count={bop.newComments}
                          size="default"
                          style={{
                            backgroundColor: "rgb(219, 149, 35)",
                            marginLeft: 2,
                            marginBottom: 2,
                          }}
                        />
                      </span>
                    </span>
                  </Space>
                </Link>
              </Space>
            </Col>

            <Col xs={24}>
              <Space direction="vertical" className="table__ticket-info">
                <small>
                  Dodane{" "}
                  <strong>
                    {moment(bop.addDate * 1000).format("DD.MM.YYYY HH:mm")}
                  </strong>{" "}
                  przez{" "}
                  <strong>
                    {" "}
                    {bop.reporter?.name} {bop.reporter?.surname}
                  </strong>
                </small>
                <small>
                  Panel <strong>{bop.company?.name}</strong>{" "}
                </small>
                <small>
                  Ostatnia aktualizacja{" "}
                  <strong>
                    {moment(bop.lastUpdated * 1000).format("DD.MM.YYYY HH:mm")}
                  </strong>{" "}
                </small>
              </Space>
            </Col>
          </Row>
        );
      },
      ellipsis: false,
      sorter: (a: ITaskList, b: ITaskList) => {
        return a.lastUpdated - b.lastUpdated;
      },
      showSorterTooltip: false,
    },
    {
      title: "Tytuł",
      dataIndex: "title",
      key: "title",
      width: 230,
      hidden: isMobile,
      className: "table__column--title",
      render: (title: string, bop: any) => {
        return (
          <Space direction="vertical" className="ant-space-break-words">
            <Link to={`/bop/${bop.id}`} className="jb-link jb-link--secendary">
              <Space className="ant-space-break-words">
                {bop?.status.id === 2 && (
                  <LockOutlined style={{ color: "red" }} />
                )}
                <span>
                  <TrimmedTitle title={`#${bop.id} - ${bop.title}`} />
                  <span
                    style={{
                      marginLeft: 6,
                    }}
                  >
                    {!bop.isVisited && (
                      <Badge
                        count="Nowy"
                        size="default"
                        style={{
                          backgroundColor: "crimson",
                          marginLeft: 2,
                          marginBottom: 2,
                        }}
                      />
                    )}
                    <Badge
                      count={bop.newComments}
                      size="default"
                      style={{
                        backgroundColor: "rgb(219, 149, 35)",
                        marginLeft: 2,
                        marginBottom: 2,
                      }}
                    />
                  </span>
                </span>
              </Space>
            </Link>
            <small>
              Dodane{" "}
              <strong>
                {moment(bop.addDate * 1000).format("DD.MM.YYYY HH:mm")}
              </strong>{" "}
              przez{" "}
              <strong>
                {" "}
                {bop.reporter?.name} {bop.reporter?.surname}
              </strong>
              {/* ost. odp.{" "} <strong>Piotr Nowak</strong> */}
            </small>
          </Space>
        );
      },
      ellipsis: false,
      sorter: (a: any, b: any) => {
        if (!a.title || !b.title) return -1;
        return a.title.localeCompare(b.title);
      },
      showSorterTooltip: false,
    },
    {
      title: "Panel",
      dataIndex: "company",
      key: "company",
      hidden: isMobile,
      width: 180,
      render: (company: any, group: any) => {
        return <Space direction="vertical">{company.name}</Space>;
      },
      ellipsis: false,
      sorter: (a: any, b: any) => {
        if (!a.name || !b.name) return -1;
        return a.name.localeCompare(b.name);
      },
    },
    {
      title: "Zgłaszający",
      dataIndex: "reporter",
      key: "reporter",
      hidden: isMobile,
      width: 180,
      render: (reporter: any, group: any) => {
        return (
          <Space direction="vertical">
            <Space direction="horizontal">
              <UserIcon user={reporter} />
              <Space direction="horizontal">
                <span>
                  {/* eslint-disable-next-line react/destructuring-assignment */}
                  {reporter.name} {reporter.surname}
                </span>
              </Space>
            </Space>
          </Space>
        );
      },
      ellipsis: false,
      showSorterTooltip: false,
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      hidden: isMobile,
      width: 180,
      render: (status: any, group: any) => {
        return (
          <Tag color={status?.id === 1 ? "green" : "red"}>{status?.name}</Tag>
        );
      },
      ellipsis: false,
      showSorterTooltip: false,
    },

    {
      title: "Ost. aktualizacja",
      dataIndex: "lastUpdated",
      key: "lastUpdated",
      hidden: isMobile,
      width: 170,
      ellipsis: true,
      fixed: "right" as const,
      render: (lastUpdated: number, record: any) => {
        return (
          <Space
            direction="vertical"
            size="small"
            align="center"
            style={{ textAlign: "center" }}
          >
            <Text>{moment(lastUpdated * 1000).format("DD.MM.YYYY HH:mm")}</Text>
            {/* <small> */}
            {/*  Bez odpowiedzi od <br /> */}
            {/*  <strong>10 dni</strong> */}
            {/* </small> */}
          </Space>
        );
      },
      sorter: (a: ITaskList, b: ITaskList) => {
        return a.lastUpdated - b.lastUpdated;
      },
      showSorterTooltip: false,
    },
  ];
  columns = columns.filter((row) => !row.hidden);
  return (
    <Table
      scroll={{ x: isMobile ? "100%" : 1000 }}
      style={{ width: "100%" }}
      locale={{ emptyText: "Brak danych" }}
      className="calendar-page__table table"
      size="middle"
      dataSource={tasks}
      columns={columns}
      pagination={{ position: ["bottomRight"], pageSize: 20 }}
      loading={isRequest}
      rowKey="id"
    />
  );
};

export default TicketList;
