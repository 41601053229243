import axios, { AxiosError } from "axios";
import { openNotificationWithIcon } from "../utils/common";
import store from "../reducers/store";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // timeout: 15000,
});

export const removeToken = () => {
  instance.defaults.headers.common.Authorization = "";
  delete instance.defaults.headers.common.Authorization;
  instance.defaults.headers.common.companyuui = "";
  delete instance.defaults.headers.common.companyuuid;
  localStorage.removeItem("token");
  localStorage.removeItem("company_uuid");
  store.dispatch({
    type: "LOGOUT",
  });
};

instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    openNotificationWithIcon(
      "error",
      "Wystąpił problem z połączeniem z serwerem"
    );
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response) {
      const { status, config } = error.response;
      if (status === 400) {
        return Promise.reject(error);
      }
      if (
        status === 404 &&
        !config.url?.includes("forgot") &&
        !config.url?.includes("reset") &&
        !config.url?.includes("check_token")
      ) {
        openNotificationWithIcon("error", "Nie znaleziono zasobu");
        return Promise.reject(error);
      }
      if (status === 409) {
        return Promise.reject(error);
      }
      if (status === 401 && !config.url?.includes("login")) {
        removeToken();
        return Promise.reject(error);
      }
      if (status === 401 && config.url?.includes("login")) {
        return Promise.reject(error);
      }

      if (status === 413) {
        openNotificationWithIcon("error", "Plik jest za duży");
        return Promise.reject(error);
      }

      if (error.code === "ERR_INTERNET_DISCONNECTED") {
        return Promise.reject(error);
      }
      if (error.code === "ERR_NETWORK") {
        return Promise.reject(error);
      }

      // openNotificationWithIcon(
      //   "error",
      //   "Wystąpił problem z połączeniem z serwerem"
      // );
      return Promise.reject(error);
    }

    if (error.code === "413") {
      openNotificationWithIcon("error", "Plik jest za duży");
      return Promise.reject(error);
    }

    if (error.code === "ERR_CANCELED") {
      return Promise.reject(error);
    }

    if (error.code === "ERR_INTERNET_DISCONNECTED") {
      return Promise.reject(error);
    }
    if (error.code === "ERR_NETWORK") {
      return Promise.reject(error);
    }

    // openNotificationWithIcon(
    //   "error",
    //   "Wystąpił problem z połączeniem z serwerem"
    // );
    return Promise.reject(error);
  }
);

const token = localStorage.getItem("token");
const companyUuid = localStorage.getItem("company_uuid");

export const setToken = (value: string): void => {
  instance.defaults.headers.common.Authorization = "";
  delete instance.defaults.headers.common.Authorization;

  if (value) {
    instance.defaults.headers.common.Authorization = `Bearer ${value}`;
  }
};

export const setCompanyUuid = (value: string): void => {
  instance.defaults.headers.common.companyuuid = "";
  delete instance.defaults.headers.common.companyuuid;

  if (value) {
    instance.defaults.headers.common.companyuuid = value;
  }
};

if (token) {
  setToken(token);
}

if (companyUuid) {
  setCompanyUuid(companyUuid);
}

export default instance;
