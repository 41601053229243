import { Button, Tooltip } from "antd";
import moment from "moment/moment";
import { CarFilled } from "@ant-design/icons";
import React from "react";
import { UserFilledIcon } from "../UserFillled";
import { UserOutlinedIcon } from "../UserOutlined";

interface IUserIcon {
  leaders?: any[];
  user: any;
  type?: string;
}

const defaultProps = {
  leaders: [],
  type: "",
};

const isUserOnHolidays = (user: any) => {
  if (!user.onHolidays) return false;

  const now = moment().unix();
  return user.holidaysFrom <= now && user.holidaysUntil >= now;
};
export const UserIcon = (props: IUserIcon) => {
  const { user, leaders, type } = props;

  const onHolidays = isUserOnHolidays(user);

  if (type === "button") {
    return (
      <>
        {onHolidays ? (
          <Tooltip
            title={`Użytkownik przebywa na urlopie w dniach ${moment(
              user.holidaysFrom * 1000
            ).format("DD.MM.YYYY")} - ${moment(
              user.holidaysUntil * 1000
            ).format("DD.MM.YYYY")}. ${
              user.holidaysReason ? user.holidaysReason : ""
            }`}
          >
            <Button
              type="link"
              icon={<CarFilled style={{ color: "orange" }} />}
            />
          </Tooltip>
        ) : null}
        {/* eslint-disable-next-line no-nested-ternary */}
        {user.typeAccount === 1 && !onHolidays ? (
          leaders?.find((leader: any) => leader.user.id === user.id) ? (
            <Tooltip title="Opiekun">
              <Button
                type="link"
                icon={<UserFilledIcon style={{ fill: "black" }} />}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Użytkownik">
              <Button type="link" icon={<UserOutlinedIcon />} />
            </Tooltip>
          )
        ) : null}

        {user.typeAccount === 2 && !onHolidays && (
          <Tooltip title="Administrator">
            <Button
              type="link"
              icon={<UserFilledIcon style={{ fill: "green" }} />}
            />
          </Tooltip>
        )}
      </>
    );
  }

  return (
    <>
      {onHolidays ? (
        <Tooltip
          title={`Użytkownik przebywa na urlopie w dniach ${moment(
            user.holidaysFrom * 1000
          ).format("DD.MM.YYYY")} - ${moment(user.holidaysUntil * 1000).format(
            "DD.MM.YYYY"
          )}. ${user.holidaysReason ? user.holidaysReason : ""}`}
        >
          <CarFilled style={{ color: "orange" }} />
        </Tooltip>
      ) : null}

      {/* eslint-disable-next-line no-nested-ternary */}
      {user.typeAccount === 1 && !onHolidays ? (
        // eslint-disable-next-line react/prop-types
        leaders?.find((leader: any) => leader.user.id === user.id) ? (
          <Tooltip title="Opiekun">
            <UserFilledIcon style={{ fill: "black" }} />
          </Tooltip>
        ) : (
          <Tooltip title="Użytkownik">
            <UserOutlinedIcon />
          </Tooltip>
        )
      ) : null}

      {user.typeAccount === 2 && !onHolidays && (
        <Tooltip title="Administrator">
          <UserFilledIcon style={{ fill: "greenx" }} />
        </Tooltip>
      )}
    </>
  );
};
UserIcon.defaultProps = defaultProps;
