import React from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { ConfigProvider } from "antd";

import { Helmet } from "react-helmet";

import locale from "antd/es/locale/pl_PL";
import moment from "moment";
import "moment/locale/pl";

import Login from "./components/Auth/Login";
import "./app.scss";
import PrivateRoute from "./components/PrivateRoute";
import DashboardBase from "./components/DashboardBase";

import store from "./reducers/store";

import ScrollToTop from "./components/Shared/ScrollToTop";

import CompaniesPage from "./components/Pages/Companies";

import BopPage from "./components/Pages/Bop";
import NewBop from "./components/Pages/Bop/new";
import BopDetailPage from "./components/Pages/Bop/detail";

moment.locale("pl");

function App(): JSX.Element {
  return (
    <Provider store={store}>
      <Router>
        <Helmet title="Jobin" defer={false} />
        <ScrollToTop />
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/" exact>
            <PrivateRoute>
              <DashboardBase title="Lista firm" subtitle="Panel zarządzania">
                <CompaniesPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/bop" exact>
            <PrivateRoute>
              <DashboardBase title="BOP" subtitle="Biuro obsługi petenta">
                <BopPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/bop/new" exact>
            <PrivateRoute>
              <DashboardBase title="Bop" subtitle="Nowy">
                <NewBop />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route
            path={[
              "/bop/:id",
              "/bop/:id/e/:eventId",
              "/bop/:id/c/:commentId",
              "/bop/:id/h/:hiddenId",
            ]}
            exact
            render={(props) => {
              return (
                <>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <PrivateRoute {...props}>
                    <DashboardBase
                      title="Zgłoszenia"
                      subtitle="BOP"
                      key={new Date().getTime()}
                    >
                      <BopDetailPage />
                    </DashboardBase>
                  </PrivateRoute>
                </>
              );
            }}
          />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
