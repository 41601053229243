import React, { PropsWithChildren, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Badge, Card, Col, Menu, Row, Space, Tabs } from "antd";
import { AxiosError, AxiosResponse } from "axios";
import { FormikErrors } from "formik";
import { Helmet } from "react-helmet";
import { AppState } from "../../../reducers";
import {
  mergeByProperty,
  openNotificationWithIcon,
  transformToFormikError,
} from "../../../utils/common";

import {
  getTicketGroup,
  getTicketGroupLeaders,
  getTicketGroupMembers,
  getTicketGroupTicketTypes,
} from "../../../actions/ticketgroups";

import { getUsers } from "../../../actions/users";
import BopBasicForm, { IBopBasicFormValues } from "./NewBopForm/BasicForm";

import Can from "../../Shared/Can";
import { Priority, PriorityColor } from "../../../types/ticket-group";
import { MetaTitle } from "../../Shared/MetaTitle";
import BopService from "../../../services/bop-service";

const { TabPane } = Tabs;

interface IRouteParams {
  id: string;
  groupId: string;
}
interface INewTicketPageProps {
  loggedUser: any;

  displayNotification: boolean;
}

const NewBop = (props: PropsWithChildren<INewTicketPageProps>): JSX.Element => {
  const { groupId } = useParams<IRouteParams>();
  const history = useHistory();
  const { displayNotification, loggedUser } = props;

  const onSave = (
    values: IBopBasicFormValues,
    callbackRequestCompleted: () => void,
    setFormErrors: (errors: FormikErrors<any>) => void
  ) => {
    BopService.create(values)
      .then((response: AxiosResponse) => {
        openNotificationWithIcon("success", "Zgłoszenie dodane");
        history.replace(`/bop/${response.data.id}`);
        callbackRequestCompleted();
      })
      .catch((err: AxiosError<{ detail?: string }>) => {
        callbackRequestCompleted();
        if (err.response?.status === 400) {
          const formikResponse = transformToFormikError(err);
          setFormErrors(formikResponse);
        }

        if (err.response?.status === 403) {
          openNotificationWithIcon("error", err.response?.data?.detail || "");
        }
      });
  };

  return (
    <Can type="bop_create" renderError>
      <div className="ticket_group-edit-page">
        <MetaTitle title="Nowy wątek" displayBadge={displayNotification} />
        <Row gutter={16}>
          <Col span={24}>
            <Card bordered={false} style={{ width: "100%" }}>
              <Row gutter={16} align="middle" justify="center">
                <Col lg={24} xl={20} xxl={12}>
                  <BopBasicForm isLoading={false} onSave={onSave} />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </Can>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state: AppState) => {
  return {
    loggedUser: state.auth.logged,
    displayNotification: state.notifications.newNotificationIndicator,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewBop);
