import { AxiosResponse } from "axios";
import { RcFile } from "antd/lib/upload/interface";
import api from "../axiosInstance";

class AdminService {
  getCompanies = async (): Promise<AxiosResponse> => {
    const response = await api.get("companies");
    return response;
  };

  acceptRegistration = async (id: string): Promise<AxiosResponse> => {
    const response = await api.post(`/registration/${id}/accept`);
    return response;
  };

  declineRegistration = async (id: string): Promise<AxiosResponse> => {
    const response = await api.post(`/registration/${id}/decline`);
    return response;
  };
}

export default new AdminService();
