/* eslint-disable-next-line react/style-prop-object */
import React from "react";
import Icon from "@ant-design/icons";

const Rocket = () => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width="50"
    height="50"
    viewBox="0 0 504.123 504.123"
    xmlSpace="preserve"
  >
    {/* eslint-disable-next-line react/style-prop-object */}
    <circle
      style={{ fill: "rgba(219,149,35,0.9)" }}
      cx="252.062"
      cy="252.062"
      r="252.062"
    />
    <rect
      x="89.403"
      y="378.88"
      style={{ fill: "#324A5E " }}
      width="136.665"
      height="22.449"
    />
    <polygon
      style={{ fill: "rgb(26, 54, 208)" }}
      points="200.468,187.865 89.403,357.612 89.403,378.88 209.92,378.88 "
    />
    <rect
      x="278.055"
      y="378.88"
      style={{ fill: "#324A5E " }}
      width="136.665"
      height="22.449"
    />
    <polygon
      style={{ fill: "rgb(21, 47, 204)" }}
      points="303.655,187.865 414.72,357.612 414.72,378.88 294.203,378.88 "
    />
    <path
      style={{ fill: "#DBDBDB " }}
      d="M212.677,95.311c-14.572,34.658-23.631,79.557-23.631,99.249c0,51.594,0,206.375,0,206.375h126.031
	c0,0,0-154.782,0-206.375c0-20.086-9.058-64.591-23.631-99.249H212.677z"
    />
    <path
      style={{ fill: "#EAEAEA " }}
      d="M230.794,95.311c-7.877,34.658-12.603,79.557-12.603,99.249c0,51.594,0,206.375,0,206.375h68.135
	c0,0,0-154.782,0-206.375c0-20.086-5.12-64.591-12.603-99.249H230.794z"
    />
    <g>
      <path
        style={{ fill: "#324A5E " }}
        d="M275.298,145.723c-12.997-12.997-33.871-12.997-46.474,0l-13.785-13.785
		c20.48-20.48,53.957-20.48,74.437,0L275.298,145.723z"
      />
      <rect
        x="181.169"
        y="400.935"
        style={{ fill: "#324A5E " }}
        width="54.351"
        height="23.237"
      />
    </g>
    <path
      style={{ fill: "#FFFFFF " }}
      d="M235.126,400.935h-53.957v-63.803c0-13.391,11.028-24.418,24.418-24.418h5.12
	c13.391,0,24.418,11.028,24.418,24.418V400.935z"
    />
    <rect
      x="268.997"
      y="400.935"
      style={{ fill: "#324A5E " }}
      width="54.351"
      height="23.237"
    />
    <path
      style={{ fill: "#FFFFFF " }}
      d="M322.954,400.935h-53.957v-63.803c0-13.391,11.028-24.418,24.418-24.418h5.12
	c13.391,0,24.418,11.028,24.418,24.418V400.935z"
    />
    <g>
      <circle
        style={{ fill: "#54C0EB " }}
        cx="146.511"
        cy="341.071"
        r="17.723"
      />
      <circle
        style={{ fill: "#54C0EB " }}
        cx="357.612"
        cy="341.071"
        r="17.723"
      />
    </g>
    <path
      style={{ fill: "#324A5E " }}
      d="M252.062,48.837L252.062,48.837c-14.966,0-28.751,20.48-39.385,46.474h78.769
	C280.812,69.317,267.028,48.837,252.062,48.837z"
    />
    <g>
      <polygon
        style={{ fill: "#FFFFFF " }}
        points="128.788,200.468 131.938,210.314 142.178,210.314 133.908,216.222 137.058,225.674
		128.788,219.766 120.517,225.674 123.668,216.222 115.397,210.314 125.637,210.314 	"
      />
      <polygon
        style={{ fill: "#FFFFFF " }}
        points="388.332,131.938 394.634,150.843 414.72,150.843 398.572,162.658 404.48,181.957
		388.332,170.142 372.185,181.957 378.486,162.658 361.945,150.843 382.031,150.843 	"
      />
      <polygon
        style={{ fill: "#FFFFFF " }}
        points="146.511,86.646 152.418,105.945 172.505,105.945 156.357,117.76 162.658,136.665
		146.511,124.849 129.969,136.665 136.271,117.76 120.123,105.945 140.209,105.945 	"
      />
      <polygon
        style={{ fill: "#FFFFFF " }}
        points="396.603,236.308 398.572,242.215 404.48,242.215 399.754,245.76 401.329,251.668
		396.603,248.123 391.483,251.668 393.452,245.76 388.332,242.215 394.634,242.215 	"
      />
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);

export const RocketIcon = (iconProps: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon component={Rocket} {...iconProps} />
);

export default Rocket;
