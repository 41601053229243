import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import {
  Alert,
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Grid,
  Popover,
  Progress,
  Row,
  Skeleton,
  Space,
  Spin,
  Tooltip,
  Upload,
} from "antd";
import Sticky from "react-stickynode";

import { Link, useHistory, useParams } from "react-router-dom";
import Text from "antd/es/typography/Text";
import HTMLReactParser from "html-react-parser";
import {
  BookOutlined,
  CaretRightOutlined,
  CommentOutlined,
  CopyOutlined,
  DollarCircleOutlined,
  EditOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  LockOutlined,
  PaperClipOutlined,
  PauseOutlined,
  PhoneOutlined,
  ReadOutlined,
  RocketOutlined,
  StarOutlined,
  StopOutlined,
  UserOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { AxiosError, AxiosResponse } from "axios";
import { UploadChangeParam } from "antd/lib/upload/interface";
// eslint-disable-next-line import/no-extraneous-dependencies
import { FormikErrors } from "formik";
import { AppState } from "../../../reducers";
import TicketService from "../../../services/tickets-service";

import {
  setCurrentEntityForbidden,
  setCurrentGroupTitle,
  setCurrentTicketTitle,
} from "../../../actions/global";
import {
  clearCommentsState,
  clearTicketState,
  createComment,
  fetchComments,
  getTicket,
  updateTicket,
} from "../../../actions/tickets";
import AttachmentsList from "./attachments";
import Comments from "./comments";
import {
  formatMoney,
  formatSeconds,
  getMobileOperatingSystem,
  makeAvatarName,
  openNotificationWithIcon,
  placeCaretAtEnd,
  stringToColour,
  transformToFormikError,
} from "../../../utils/common";
import TicketType from "../../Shared/TicketType";
import {
  getTicketGroupLeaders,
  getTicketGroupMembers,
  getTicketGroupTicketTypes,
} from "../../../actions/ticketgroups";

import {
  addWorktime,
  endWorktime,
  getTicketWorktimesShort,
  getUserActiveWorktime,
  getUserLastWorktimes,
  startWorktime,
} from "../../../actions/worktimes";
import Can from "../../Shared/Can";

import { getUsers } from "../../../actions/users";

import { setLoggedUser } from "../../../actions/auth";

import useKeyPress from "../../../hooks/useKeyPress";
import { MetaTitle } from "../../Shared/MetaTitle";
import UsersService from "../../../services/users-service";
import NoContentError from "../../Shared/NoContentError";
import BopService from "../../../services/bop-service";
import LeaveTicketButton from "../../Shared/LeaveTicketButton";
import OpenBop from "./OpenBop";
import CloseBop from "./CloseBop";

interface IRouteParams {
  id: string;
  commentId: string;
  eventId?: string;
}
const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

const BopDetailPage = (props: PropsWithChildren<any>): JSX.Element => {
  const { id, commentId, eventId } = useParams<IRouteParams>();
  const { useBreakpoint } = Grid;
  const history = useHistory();
  const screens = useBreakpoint();
  const [sticky, setSticky] = useState(true);
  const [bop, setBop] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isCommentsExpanded, setCommentsExpanded] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const [uploadList, setUploadList] = useState<any[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isUnreadLoading, setUnreadLoading] = useState(false);
  const [scratchpad, setScratchpad] = useState({ comment: null, hidden: null });
  const [comments, setComments] = useState([]);
  const scrollRef = useRef<any>();
  const hiddenRef = useRef<any>();
  const {
    notifications,
    isRequest,
    loggedUser,
    setCurrentTicketTitleAction,
  } = props;

  const [attachments, setAttachments] = useState([]);
  const [linkId, setLinkId] = useState<string | null>(null);
  const [isAttachmentsLoading, setAttachmentsLoading] = useState(false);
  // const fetchAttachments = useCallback(() => {
  //   setAttachmentsLoading(true);
  //   TicketService.getTicketAttachments(id)
  //     .then((response) => {
  //       setTicketAttachments(response.data);
  //     })
  //     .finally(() => {
  //       setAttachmentsLoading(false);
  //     });
  // }, [id]);
  const focusComment = (event: KeyboardEvent) => {
    if (event.key.toLowerCase() === "u") {
      if (hiddenRef) {
        placeCaretAtEnd(hiddenRef?.current?.querySelector(".ql-editor"));
      }
    }
    if (event.key.toLowerCase() === "k") {
      if (scrollRef) {
        placeCaretAtEnd(scrollRef?.current?.querySelector(".ql-editor"));
      }
    }
  };
  useKeyPress("shift+ctrl", ["k", "u"], focusComment);

  const handleProgressChange = (file: any, value: number) => {
    setUploadList((list) => {
      const idx = list.findIndex((item: any) => item.id === file.uid);
      if (idx > -1) {
        const copyItem = { ...list[idx] };

        copyItem.progress = value;

        return [...list.slice(0, idx), copyItem, ...list.slice(idx + 1)];
      }
      return [...list];
    });
  };

  const handleChange = (info: UploadChangeParam) => {
    const { file, fileList } = info;

    const item = {
      date: moment().unix(),
      fileMimeType: file.type,
      fileName: file.name,
      fileOriginalName: file.name,
      fileSize: file.size,
      progress: 0,
      hidden: false,
      id: file.uid,
      ticket: {},
      user: {},
      status: file.status,
    };

    setUploadList((list) => [item, ...list]);
  };

  const handleUpload = (options: any) => {
    // setIsUploading(true);
    return BopService.uploadFile(
      id,
      Math.floor(Date.now() / 1000),
      options.file,
      (change) => handleProgressChange(options.file, change)
    )
      .then((response) => {
        setUploadList((list) => {
          const idx = list.findIndex(
            (item: any) => item.id === options.file.uid
          );
          if (idx > -1) {
            return [
              ...list.slice(0, idx),
              response.data,
              ...list.slice(idx + 1),
            ];
          }
          return [...list];
        });
      })
      .catch((err) => {
        setUploadList((list) => {
          const idx = list.findIndex(
            (item: any) => item.id === options.file.uid
          );
          const copyItem = { ...list[idx] };
          copyItem.progress = 0;
          copyItem.status = "error";
          if (idx > -1) {
            return [...list.slice(0, idx), copyItem, ...list.slice(idx + 1)];
          }
          return [...list];
        });
      });
  };

  const handleSubmit = (
    comment: any,
    reloadTicket = false,
    leaveTicket = false
  ): Promise<any> => {
    // return Promise.resolve();
    const bopId = bop?.id;

    return BopService.createComment(bopId, comment)
      .then((res: any) => {
        console.log("ok");
        // reloadTicket
        // if (leaveTicket) {
        //   openNotificationWithIcon("success", "Zostałeś wypisany z wątku");
        //   getTicketCommentsAction(tikcetId);
        //   return history.replace(`/group/${ticket?.group?.id}`);
        // }

        return BopService.getBop(bopId)
          .then((response) => {
            setBop(response.data);
            return BopService.getComments(bopId).then((commentsRes) => {
              setScratchpad({ comment: null, hidden: null });
              setComments(commentsRes.data);
              openNotificationWithIcon("success", "Komentarz dodany");
              return Promise.resolve(commentsRes.data);
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
        // return getTicketAction(tikcetId, true).then((response: any) => {
        //   fetchAttachments();
        //   return getTicketCommentsAction(tikcetId, true).then(
        //     (commentsRes: any) => {
        //       setLoggedUserAction({ archive: true });
        //       setScratchpad({ comment: null, hidden: null });
        //       openNotificationWithIcon("success", "Komentarz dodany");
        //       return Promise.resolve(commentsRes);
        //     }
        //   );
        // });
      })
      .catch((err: AxiosError) => {
        console.log(err);
        if (err.response?.status === 400) {
          openNotificationWithIcon(
            "error",
            "Wystąpił błąd w trakcie wykonywania akcji"
          );
        }

        if (err.response?.status === 403) {
          openNotificationWithIcon(
            "error",
            "Nie posiadasz wystarczających uprawnień do wykonania tej akcji."
          );
        }
        return Promise.reject(err);
      });
  };

  const fetchBop = (bopId: string | number) => {
    setIsLoading(true);
    BopService.getBop(bopId)
      .then((response) => {
        setBop(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAutoSave = (comment: any): Promise<any> => {
    // if (isCreateCommentRequest) {
    //   return Promise.reject();
    // }

    const bopId = bop?.id;

    return BopService.createScratchpad(bopId, comment)
      .then((res: any) => {})
      .catch((err: AxiosError) => {
        return Promise.reject(err);
      });
  };

  const handleSubmitMiniForm = (
    values: any,
    onRequestComplete: () => void,
    setError?: (formikResponse: FormikErrors<any>) => void
  ) => {
    const { entityId, ...vals } = values;
    const tikcetId = bop?.id;
    BopService.update(tikcetId, vals)
      .then((res: any) => {
        fetchBop(tikcetId);
        BopService.getScratchpad(id).then((response: AxiosResponse) => {
          setScratchpad(response.data);
        });
        BopService.getComments(id).then((commentsRes) => {
          setComments(commentsRes.data);
        });
        onRequestComplete();
      })
      .catch((err: any) => {
        const formikResponse = transformToFormikError(err);
        if (err.response?.status === 400) {
          openNotificationWithIcon(
            "error",
            "Wystąpił błąd w trakcie wykonywania akcji"
          );
          if (setError) setError(formikResponse);
        }

        if (err.response?.status === 403) {
          openNotificationWithIcon(
            "error",
            "Nie posiadasz wystarczających uprawnień do wykonania tej akcji."
          );
          if (setError) setError(formikResponse);
        }
      });
  };

  const toggleCommentsExpand = () => {
    UsersService.updateMeta({ commentsExpanded: !isCommentsExpanded });
    setCommentsExpanded(!isCommentsExpanded);
  };

  const scrollToInformation = () => {
    const element = document.getElementById(`informations`);

    if (!element) return;

    setTimeout(() => {
      requestAnimationFrame(() => {
        const platform = getMobileOperatingSystem();
        if (platform === "iOS") element.scrollIntoView(true);
        else
          element.scrollIntoView({
            block: "start",
            behavior: "auto",
          });
      });
    }, 2);
  };

  useEffect(() => {
    if (id) {
      fetchBop(id);
      BopService.getScratchpad(id).then((response: AxiosResponse) => {
        setScratchpad(response.data);
      });
      BopService.getComments(id).then((commentsRes) => {
        setComments(commentsRes.data);
      });
      // getTicketCommentsAction(id);
      // fetchAttachments();
    }
  }, [id]);

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    const isXlBreakpoint = Object.entries(screens)
      .filter((screen) => !!screen[1])
      .filter((screen) => screen[0] === "xl").length;

    const isMdBreakpoint = Object.entries(screens)
      .filter((screen) => !!screen[1])
      .filter((screen) => screen[0] === "md").length;

    setSticky(!!isXlBreakpoint);
    setIsMobile(!isMdBreakpoint);
  }, [screens]);

  useEffect(() => {
    if (bop) {
      setCurrentTicketTitleAction(`#${bop?.id} - ${bop?.title}`);
    }
  }, [bop, setCurrentTicketTitleAction]);

  useEffect(() => {
    return () => {
      setCurrentTicketTitleAction(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCurrentTicketTitleAction]);

  useEffect(() => {
    const toggled =
      typeof loggedUser?.meta?.commentsExpanded === "boolean"
        ? loggedUser?.meta?.commentsExpanded
        : false;
    setCommentsExpanded(toggled);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser?.meta?.deadlinesVisible]);

  useEffect(() => {
    if (eventId) setLinkId(`event-${eventId}`);
    else if (commentId) setLinkId(`comment-${commentId}`);
    else setLinkId(null);
  }, [eventId, commentId]);

  const canBopOpen = Can({
    entity: bop,
    type: "bop_open",
    children: <></>,
  });

  return (
    <Can renderError type="ticket_view">
      <NoContentError noContent={!isLoading && !bop}>
        <div className="ticket-page">
          <MetaTitle
            title={bop ? `#${bop?.id} - ${bop?.title}` : "Podgląd zgłoszenia"}
            displayBadge={notifications}
          />

          <Row gutter={[16, 16]}>
            <Col xs={24} lg={24} xl={15} xxl={17}>
              <Card bordered={false} size={isMobile ? "small" : "default"}>
                {isLoading === true ? (
                  <>
                    <Skeleton loading={isLoading} avatar active>
                      <Text />
                      <Text />
                      <Text />
                    </Skeleton>
                    <Skeleton loading={isLoading} avatar active>
                      <Text />
                      <Text />
                      <Text />
                    </Skeleton>
                    <Skeleton loading={isLoading} avatar active>
                      <Text />
                      <Text />
                      <Text />
                    </Skeleton>
                    <Skeleton loading={isLoading} avatar active>
                      <Text />
                      <Text />
                      <Text />
                    </Skeleton>
                    <Skeleton loading={isLoading} avatar active>
                      <Text />
                      <Text />
                      <Text />
                    </Skeleton>
                  </>
                ) : (
                  <>
                    <Row justify="space-between" align="top">
                      <Col xs={24} sm={18} lg={20}>
                        <Row justify="start" align="middle" wrap={false}>
                          <Col>
                            <h2
                              style={{
                                margin: 0,
                                marginLeft: isMobile ? 0 : "8px",
                              }}
                            >
                              <Space className="ant-space-break-words">
                                <span>
                                  #{bop?.id} - {bop?.title}
                                </span>
                              </Space>
                            </h2>
                          </Col>
                        </Row>

                        {!isMobile ? (
                          <div>
                            <small
                              style={{
                                color: "#666",
                                fontSize: 12,
                                fontWeight: "normal",
                              }}
                            >
                              Utworzono:
                              <strong>
                                {" "}
                                {moment(bop?.addDate * 1000).format(
                                  "DD.MM.Y HH:mm"
                                )}
                              </strong>
                              , Zaktualizowano:
                              <strong>
                                {" "}
                                {moment(bop?.lastUpdated * 1000).format(
                                  "DD.MM.Y HH:mm"
                                )}
                              </strong>
                              , Zgłaszający:
                              <strong>
                                {` ${bop?.reporter.name} ${bop?.reporter.surname}`}
                              </strong>
                            </small>
                          </div>
                        ) : (
                          <Row>
                            <Col span={24}>
                              <small
                                style={{
                                  color: "#666",
                                  fontSize: 12,
                                  fontWeight: "normal",
                                }}
                              >
                                Utworzono:
                                <strong>
                                  {" "}
                                  {moment(bop?.addDate * 1000).format(
                                    "DD.MM.Y HH:mm"
                                  )}
                                </strong>
                              </small>
                            </Col>
                            <Col span={24}>
                              <small
                                style={{
                                  color: "#666",
                                  fontSize: 12,
                                  fontWeight: "normal",
                                }}
                              >
                                Zaktualizowano:
                                <strong>
                                  {" "}
                                  {moment(bop?.lastUpdated * 1000).format(
                                    "DD.MM.Y HH:mm"
                                  )}
                                </strong>
                              </small>
                            </Col>
                            <Col span={24}>
                              <small
                                style={{
                                  color: "#666",
                                  fontSize: 12,
                                  fontWeight: "normal",
                                }}
                              >
                                Zgłaszający:
                                <strong>
                                  {` ${bop?.reporter.name} ${bop?.reporter.surname}`}
                                </strong>
                              </small>
                            </Col>
                          </Row>
                        )}
                      </Col>
                      <Col xs={24} sm={6} lg={4}>
                        <Row justify="end" align="top">
                          <Space>
                            <Can type="bop_attachment" entity={bop}>
                              <Tooltip title="Dodaj załącznik">
                                <Upload
                                  multiple
                                  disabled={isUploading}
                                  listType="text"
                                  showUploadList={false}
                                  customRequest={handleUpload}
                                  onChange={handleChange}
                                >
                                  <Button icon={<PaperClipOutlined />} />
                                </Upload>
                              </Tooltip>
                            </Can>
                            <Can type="bop_comment" entity={bop}>
                              <Tooltip title="Napisz komentarz">
                                <Button
                                  icon={<CommentOutlined />}
                                  onClick={() =>
                                    placeCaretAtEnd(
                                      scrollRef?.current?.querySelector(
                                        ".ql-editor"
                                      )
                                    )
                                  }
                                />
                              </Tooltip>
                            </Can>
                            {!sticky && (
                              <Tooltip title="Pokaż informacje">
                                <Button
                                  icon={<InfoCircleOutlined />}
                                  onClick={() => scrollToInformation()}
                                />
                              </Tooltip>
                            )}
                          </Space>
                        </Row>
                      </Col>
                    </Row>
                    <Divider />
                    <Space
                      style={{ width: "100%" }}
                      direction="vertical"
                      size="large"
                    >
                      <div className="ticket-page__description">
                        <Row justify="space-between" align="middle">
                          <Col>
                            <h4>Opis</h4>
                          </Col>
                        </Row>
                        <Text>{HTMLReactParser(bop?.description || "")}</Text>
                      </div>
                      <div>
                        <AttachmentsList
                          isMobile={isMobile}
                          isLoading={isAttachmentsLoading}
                          attachments={
                            bop?.attachments
                              ? [...uploadList, ...bop.attachments]
                              : uploadList
                          }
                        />
                        <Comments
                          isMobile={isMobile}
                          isSticky={sticky}
                          commentRef={scrollRef}
                          scratchpad={scratchpad}
                          ticket={bop}
                          loggedUser={loggedUser}
                          comments={comments}
                          handleAutoSave={handleAutoSave}
                          isFetching={false}
                          isSubmitting={false}
                          handleSubmit={handleSubmit}
                          toggleCommentsExpand={toggleCommentsExpand}
                          expanded={isCommentsExpanded}
                          linkId={linkId}
                        />
                      </div>
                    </Space>
                  </>
                )}
              </Card>
            </Col>
            <Col xs={24} lg={24} xl={9} xxl={7}>
              <Sticky top={80} enabled={sticky} bottomBoundary=".ticket-page">
                <Card
                  id="informations"
                  style={{ scrollMarginTop: 70 }}
                  bordered={false}
                  title="Informacje"
                  size={isMobile ? "small" : "default"}
                >
                  {isLoading === true ? (
                    <>
                      <Skeleton loading={isLoading} avatar active>
                        <Text />
                        <Text />
                        <Text />
                      </Skeleton>
                      <Skeleton loading={isLoading} avatar active>
                        <Text />
                        <Text />
                        <Text />
                      </Skeleton>
                      <Skeleton loading={isLoading} avatar active>
                        <Text />
                        <Text />
                        <Text />
                      </Skeleton>
                    </>
                  ) : (
                    <>
                      <Row gutter={[16, 16]} align="top">
                        <Col xs={10} md={6} lg={6} xl={10}>
                          <Text strong>Nazwa</Text>
                        </Col>
                        <Col xs={14} md={18} lg={16} xl={14}>
                          <Space className="ant-space-break-words">
                            {bop?.status.id === 2 && (
                              <LockOutlined style={{ color: "red" }} />
                            )}
                            <span>
                              #{bop?.id} - {bop?.title}
                            </span>
                          </Space>
                        </Col>
                        <Col xs={10} md={6} lg={6} xl={10}>
                          <Text strong>Zgłaszający</Text>
                        </Col>
                        <Col xs={14} md={18} lg={16} xl={14}>
                          <Space>
                            <Avatar
                              size={24}
                              src={bop?.reporter.img || null}
                              className="user-app__avatar"
                              style={{
                                color: "#fefefe",
                                backgroundColor: stringToColour(
                                  bop?.reporter.email || ""
                                ),
                              }}
                            >
                              {makeAvatarName(
                                bop?.reporter.deleted || false,
                                bop?.reporter.name,
                                bop?.reporter.surname
                              )}
                            </Avatar>
                            <Text>
                              {bop?.reporter.name} {bop?.reporter.surname}
                            </Text>
                          </Space>
                        </Col>

                        <Col
                          xs={10}
                          md={6}
                          lg={6}
                          xl={10}
                          className="ticket-info__label-col"
                        >
                          <Text strong className="ticket-info__label">
                            Ostatnia akcja
                          </Text>
                        </Col>
                        <Col xs={14} md={18} lg={16} xl={14}>
                          <Text strong className="ticket-info__label">
                            {moment(bop?.lastUpdated * 1000).format(
                              "DD.MM.Y HH:mm"
                            )}
                          </Text>
                        </Col>

                        <Col
                          xs={24}
                          md={6}
                          lg={6}
                          xl={10}
                          className="ticket-info__label-col"
                        >
                          <Text strong className="ticket-info__label">
                            Opcje
                          </Text>
                        </Col>
                        <Col xs={24} md={18} lg={16} xl={14}>
                          <Space size="small" align="center" wrap>
                            <Can type="bop_open" entity={bop}>
                              <OpenBop onSubmit={handleSubmitMiniForm} />
                            </Can>
                            <Can type="bop_close" entity={bop}>
                              <CloseBop onSubmit={handleSubmitMiniForm} />
                            </Can>
                          </Space>
                        </Col>
                      </Row>
                    </>
                  )}
                </Card>
              </Sticky>
            </Col>
          </Row>
        </div>
      </NoContentError>
    </Can>
  );
};

const mapDispatchToProps = {
  getTicketAction: getTicket,
  getTicketWorktimesShortAction: getTicketWorktimesShort,
  getTicketTypesAction: getTicketGroupTicketTypes,
  getTicketCommentsAction: fetchComments,
  createTicketCommentAction: createComment,
  setCurrentTicketTitleAction: setCurrentTicketTitle,
  setCurrentGroupTitleAction: setCurrentGroupTitle,
  setCurrentEntityForbiddenAction: setCurrentEntityForbidden,
  clearTicketStateActon: clearTicketState,
  clearCommentsStateAction: clearCommentsState,
  updateTicketAction: updateTicket,
  getUsersAction: getUsers,
  getLeadersAction: getTicketGroupLeaders,
  getMembersAction: getTicketGroupMembers,
  startWorktimeAction: startWorktime,
  endWorktimeAction: endWorktime,
  getActiveWorktimeAction: getUserActiveWorktime,
  getLastWorktimeAction: getUserLastWorktimes,
  addWorktimeAction: addWorktime,
  setLoggedUserAction: setLoggedUser,
};

const mapStateToProps = (state: AppState, ownProps: any) => {
  const ticketId = state.ticket.ticket?.id;
  return {
    ticket: state.ticket.ticket,
    notifications: state.notifications.newNotificationIndicator,
    users: state.users.users,
    leaders: state.ticketgroups.ticketgroupLeaders,
    members: state.ticketgroups.ticketgroupMembers,
    isCommentsRequest: state.ticket.isFetchCommentsRequest,
    isCreateCommentRequest: state.ticket.isCreateCommentRequest,
    worktime: state.worktime.ticketShort,
    worktimeRequest: state.worktime.ticketShortRequest,
    types: state.ticketgroups.ticketgroupTicketTypes,
    isRequest: state.ticket.isRequest,
    isUsersLoading: state.users.isRequest,
    isLeadersLoading: state.ticketgroups.isFetchTicketGroupLeadersRequest,
    isMembersLoading: state.ticketgroups.isFetchTicketGroupMembersRequest,
    loggedUser: state.auth.logged,
    activeWorktime: state.worktime.active,
    toggleRequest: state.worktime.toggleWorktimeRequest,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BopDetailPage);
