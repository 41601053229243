import React, { useState } from "react";
import { Button, Tooltip } from "antd";
import { LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import { openNotificationWithIcon } from "../../../utils/common";

const OpenBop = (props: any) => {
  const { onSubmit } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Tooltip title="Przywróć zgłoszenie">
      <Button
        icon={isLoading ? <LoadingOutlined spin /> : <ReloadOutlined />}
        onClick={() => {
          setIsLoading(true);
          onSubmit(
            {
              open: true,
            },
            () => {
              openNotificationWithIcon("success", "Zgłoszenie przywrócone.");
              setIsLoading(false);
            },
            () => setIsLoading(false)
          );
        }}
      />
    </Tooltip>
  );
};

export default OpenBop;
