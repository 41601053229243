import React, { PropsWithChildren, useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  Col,
  Grid,
  Row,
  Space,
  Table,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import { Link } from "react-router-dom";
import {
  ClockCircleOutlined,
  EditOutlined,
  FieldTimeOutlined,
  MessageOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import classnames from "classnames";

import Text from "antd/es/typography/Text";
import moment from "moment";
import {
  formatBytes,
  makeAvatarName,
  renderLastActivity,
  stringToColour,
  timeAgoFromTimestamp,
} from "../../../utils/common";
import RemoveRecord from "../../Shared/RemoveRecord";
import useIsMobile from "../../../hooks/useIsMobile";
import { LicenceType } from "../../../types/user";

const { TabPane } = Tabs;

interface IUsersPageProps {
  companies: ICompanyList[];
  isRequest: boolean;
  isDeleteRequest: boolean;
  onDelete: (user: any, callback: () => void) => void;
  onDecline: (id: string) => void;
  onAccept: (id: string) => void;
}

interface IRegister {
  status: number;
  name: string;
  surname: string;
  email: string;
  id: string;
  licenceType: number;
  createdAt: any;
}
interface ICompanyList {
  id: string;
  name: string;
  tickets: number;
  users: number;
  attachments: number;
  attachmentsSize: number;
  register: IRegister;
}
interface IRole {
  id: number;
  name: string;
}

const CompanyList = (
  props: PropsWithChildren<IUsersPageProps>
): JSX.Element => {
  const { companies, isRequest, onDelete, onAccept, onDecline } = props;

  const isMobile = useIsMobile();

  interface IUserAvatar {
    img: string;
    avatarColor: string | null;
    avatarBackground: string | null;
    name: string;
    surname: string;
    email: string;
  }

  let columns = [
    {
      title: "ID",
      dataIndex: "id",
      className: "table__column--name",
      key: "id",
      width: 80,
      hidden: isMobile,
      render: (id: string, user: ICompanyList) => {
        if (!id) return "";
        return `${id}`;
      },
      ellipsis: true,
      sorter: (a: ICompanyList, b: ICompanyList) => {
        if (!a.name || !b.name) return -1;
        const aName = `${a.name}`;
        const bName = `${b.name}`;
        return aName.localeCompare(bName);
      },
      showSorterTooltip: false,
    },
    {
      title: "Nazwa",
      dataIndex: "name",
      className: "table__column--name",
      key: "name",
      minWidth: 130,
      hidden: isMobile,
      render: (name: string, user: ICompanyList) => {
        if (!name) return "";
        return `${name}`;
      },
      ellipsis: true,
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: "Data rejestracji",
      dataIndex: "registrationDate",
      key: "registrationDate",
      className: "table__column--email",
      ellipsis: true,
      hidden: isMobile,
      sorter: (a: ICompanyList, b: ICompanyList) => {
        return a.register?.createdAt - b.register?.createdAt;
      },
      showSorterTooltip: false,
      render: (licenceType: string, record: ICompanyList) => {
        return record.register?.createdAt
          ? moment(record.register?.createdAt).format("DD.MM.YYYY HH:mm")
          : "-";
      },
    },
    {
      title: "Os. rejestrująca",
      dataIndex: "register",
      key: "register",
      className: "table__column--email",
      ellipsis: true,
      hidden: isMobile,
      sorter: (a: ICompanyList, b: ICompanyList) => {
        if (!a.register || !b.register) return -1;
        const aName = `${a.register?.name} ${a.register?.surname}`;
        const bName = `${b.register?.name} ${b.register?.surname}`;
        return aName.localeCompare(bName);
      },
      showSorterTooltip: false,
      render: (licenceType: string, record: ICompanyList) => {
        if (!record.register) return "-";
        return `${record.register?.name} ${record.register?.surname}`;
      },
    },
    {
      title: "E-mail",
      dataIndex: "register",
      key: "registerMail",
      className: "table__column--email",
      ellipsis: true,
      hidden: isMobile,
      sorter: (a: ICompanyList, b: ICompanyList) => {
        if (!a.register || !b.register) return -1;
        const aName = `${a.register?.email}`;
        const bName = `${b.register?.email}`;
        return aName.localeCompare(bName);
      },
      showSorterTooltip: false,
      render: (licenceType: string, record: ICompanyList) => {
        if (!record.register) return "-";
        return `${record.register?.email} ${record.register?.email}`;
      },
    },

    {
      title: "Pakiet",
      dataIndex: "licenceType",
      key: "licenceType",
      className: "table__column--email",
      ellipsis: true,
      hidden: isMobile,
      sorter: (a: ICompanyList, b: ICompanyList) => {
        return a.register?.licenceType - b.register?.licenceType;
      },
      showSorterTooltip: false,
      render: (licenceType: string, record: ICompanyList) => {
        return record.register?.licenceType
          ? LicenceType[record.register?.licenceType]
          : "-";
      },
    },
    {
      title: "Nazwa",
      dataIndex: "name",
      className: "table__column--user-table-info user-table-info",
      key: "nameMobile",
      hidden: !isMobile,
      render: (name: string, record: ICompanyList) => {
        return (
          <div className="user-table-info__container">
            <div className="user-table-info__header">
              <div style={{ width: "100%" }}>
                <div className="user-table-info__name-row">
                  <p className="user-table-info__name">{name}</p>

                  <Space size="small" align="end">
                    {record.register?.status === 1 && (
                      <Button
                        size="small"
                        type="primary"
                        onClick={() => onAccept(record.register.id)}
                      >
                        Akceptuj
                      </Button>
                    )}
                    {record.register?.status === 1 && (
                      <Button
                        size="small"
                        type="default"
                        color="warn"
                        onClick={() => onDecline(record.register.id)}
                      >
                        Odrzuć
                      </Button>
                    )}

                    {record.register?.status === 2 && (
                      <Tag color="green">Zaakceptowane</Tag>
                    )}
                    {record.register?.status === 3 && (
                      <Tag color="red">Odrzucone</Tag>
                    )}
                  </Space>
                </div>
                <Space size="small" wrap>
                  <small>
                    <strong>Data rejestracji:</strong>{" "}
                    {record.register?.createdAt
                      ? moment(record.register.createdAt).format(
                          "DD.MM.YYYY HH:mm"
                        )
                      : "-"}
                  </small>
                  <small>
                    <strong>Os. rejestrująca:</strong>{" "}
                    {record.register
                      ? `${record.register.name} ${record.register.surname}`
                      : "-"}
                  </small>
                  <small>
                    <strong>Email:</strong>{" "}
                    {record.register ? `${record.register.email}` : "-"}
                  </small>
                  <small>
                    <strong>Pakiet:</strong>{" "}
                    {record.register?.licenceType
                      ? LicenceType[record.register?.licenceType]
                      : "-"}
                  </small>
                  <small>
                    <strong>Użytkownikow:</strong> {record.users}
                  </small>
                  <small>
                    <strong>Wątków:</strong> {record.tickets}
                  </small>
                  <small>
                    <strong>Załączników:</strong> {record.attachments} (
                    {formatBytes(record.attachmentsSize, 2)})
                  </small>
                </Space>
              </div>
            </div>
          </div>
        );
      },
      sorter: (a: ICompanyList, b: ICompanyList) => {
        if (!a.name || !b.name) return -1;
        const aName = `${a.name}`;
        const bName = `${b.name}`;
        return aName.localeCompare(bName);
      },
      showSorterTooltip: false,
    },
    {
      title: "Użytkownicy",
      dataIndex: "users",
      key: "users",
      className: "table__column--email",
      ellipsis: true,
      hidden: isMobile,
      sorter: (a: ICompanyList, b: ICompanyList) => {
        return a.users - b.users;
      },
      showSorterTooltip: false,
    },
    {
      title: "Wątki",
      dataIndex: "tickets",
      key: "tickets",
      className: "table__column--email",
      ellipsis: true,
      hidden: isMobile,
      sorter: (a: ICompanyList, b: ICompanyList) => {
        return a.tickets - b.tickets;
      },
      showSorterTooltip: false,
    },
    {
      title: "Załączniki",
      dataIndex: "attachments",
      key: "attachments",
      className: "table__column--email",
      ellipsis: true,
      hidden: isMobile,
      sorter: (a: ICompanyList, b: ICompanyList) => {
        return a.attachments - b.attachments;
      },
      showSorterTooltip: false,
    },
    {
      title: "Rozmiar załączników",
      dataIndex: "attachmentsSize",
      key: "attachmentsSize",
      className: "table__column--email",
      ellipsis: true,
      hidden: isMobile,
      sorter: (a: ICompanyList, b: ICompanyList) => {
        return a.attachmentsSize - b.attachmentsSize;
      },
      showSorterTooltip: false,
      render: (size: number) => formatBytes(size, 2),
    },
    {
      title: "Akcje",
      key: "action",
      className: "table__column--actions",
      width: 200,
      hidden: isMobile,
      fixed: "right" as const,
      render: (text: string, record: ICompanyList) => (
        <Space size="middle">
          {record.register?.status === 1 && (
            <Button
              size="small"
              type="primary"
              onClick={() => onAccept(record.register.id)}
            >
              Akceptuj
            </Button>
          )}
          {record.register?.status === 1 && (
            <Button
              size="small"
              type="default"
              color="warn"
              onClick={() => onDecline(record.register.id)}
            >
              Odrzuć
            </Button>
          )}

          {record.register?.status === 2 && (
            <Tag color="green">Zaakceptowane</Tag>
          )}
          {record.register?.status === 3 && <Tag color="red">Odrzucone</Tag>}
        </Space>
      ),
    },
  ];

  columns = columns.filter((row: any) => row.hidden === false);

  return (
    <Table
      size={isMobile ? "small" : "middle"}
      locale={{ emptyText: "Brak danych" }}
      dataSource={companies}
      className="users-page__table table"
      scroll={{ x: isMobile ? 0 : 1000 }}
      columns={columns}
      pagination={{ position: ["bottomRight"] }}
      loading={isRequest}
      rowKey="id"
    />
  );
};

export default CompanyList;
